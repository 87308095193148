import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  userName = "";
  loggeduserObj: any = {
    ParamName: '',
    ParamEnName: '',
    ParamMail: '',
    ParamFirstName: '',
    ParamLastName: '',
    ParamManagerName: '',
    ParamManagerMail: '',
    ParamManagerId: '',
    ParamEmployeeId: ''
  };
  ACSuser: any;
  ACSuserDep: any;
  ACSuserparam: any;
  ACSManualUser: boolean = false;
  ACSDataLoading: boolean = false;
  constructor(private _commonService: SharedService, private spinner: NgxSpinnerService, private _appServ: AppServicesService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.spinner.show();
    sessionStorage.setItem("UnAuthorizeMsg", "Http failure, as the request is Unauthorized");

    this.ACSuser = sessionStorage.getItem('ACSuserName');
    this.ACSuserDep = sessionStorage.getItem('ACSuserDep');
    this.route.queryParams.subscribe(params => {
      this.ACSuserparam = params['ParamName'];
      if (this.ACSuser == "undefined" || this.ACSuser == undefined || this.ACSuserDep == "undefined" || this.ACSuserDep == undefined) {
        sessionStorage.setItem('ACSuserName', this.ACSuserparam);
        sessionStorage.setItem('ACSuserDep', params['DepParam']);
        sessionStorage.setItem('ACSuserLoc', params['LocParam']);
        sessionStorage.setItem('ACSuserFirstNameParam', params['FirstNameParam']);
        sessionStorage.setItem('ACSuserLastNameParam', params['LastNameParam']);
        sessionStorage.setItem('ACSuserEmailParam', params['EmailParam']);
      }
      if (params['UserName'] != undefined) {
        sessionStorage.setItem("AuthToken", params['UserName']);
      }
      if ((this.ACSuser == "undefined" || this.ACSuser == undefined || this.ACSuser == null || this.ACSuserDep == "undefined" || this.ACSuserDep == undefined || this.ACSuserDep == null) && (this.ACSuserparam == "undefined" || this.ACSuserparam == undefined || this.ACSuserparam == null)) {
        debugger;
        this._appServ.getDataLoadingStatus().subscribe((result) => {
          this.ACSDataLoading = result[0]["IsDataLoading"];
          if(!this.ACSDataLoading){
            this.SAMLService();
          }
          else
            this.spinner.hide();
        });
      }
      else {        
        this.login();
      }
    });
  }
  SAMLService() {
    this._appServ.getSAMLService().subscribe((result) => {
      window.location.href = result.toString();
      this.spinner.hide();
    },
      (error) => {
        this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  login() {
    let userName = sessionStorage.getItem('ACSuserName');
    let Dep = sessionStorage.getItem('ACSuserDep');
    let Loc = sessionStorage.getItem('ACSuserLoc');
    let FirstNameParam = sessionStorage.getItem('ACSuserFirstNameParam');
    let LastNameParam = sessionStorage.getItem('ACSuserLastNameParam');
    let EmailParam = sessionStorage.getItem('ACSuserEmailParam');
    var objName = { BasfId: userName, Department: Dep, Location: Loc, FirstName: FirstNameParam, LastName: LastNameParam, Email: EmailParam, Password: "" };
    this._appServ.getLoggedUserData(objName).subscribe((result) => {      
      this.spinner.hide();
      
      var systemlocation = window.location.hash;
      systemlocation = systemlocation.replace("#", "");
      systemlocation = systemlocation.replace("/", "");
      sessionStorage.setItem('ACSSystemLocation', systemlocation);

      if (result != "") {
        this.loggeduserObj.ParamName = result[0].BasfId;
        this.loggeduserObj.ParamEnName = "";
        this.loggeduserObj.ParamMail = result[0].Email;
        this.loggeduserObj.ParamFirstName = result[0].FirstName;
        this.loggeduserObj.ParamLastName = result[0].LastName;
        this.loggeduserObj.ParamManagerName = result[0].ManagerName;
        this.loggeduserObj.ParamManagerMail = result[0].ManagerMail;
        this.loggeduserObj.ParamManagerId = result[0].ManagerID;
        this.loggeduserObj.ParamEmployeeId = result[0].EmployeeId;
        this.loggeduserObj.ParamRoleID = result[0].RoleID;
        this.loggeduserObj.DisableBUKRS = result[0].DisableBUKRS;
        if (result[0].DisableBUKRS == undefined)
          this.loggeduserObj.DisableBUKRS = true;
        
        if (result[0].RoleID == "1")
          document.getElementById("lnkAdmin").style.display = "block";

        this._appServ.SetUserObj(this.loggeduserObj);
        this._appServ.setUserName(result[0].BasfId);
        sessionStorage.setItem("loggeduserObj", JSON.stringify(this.loggeduserObj));
      }
    },
      (error) => {
        if (error.status == 401) {
          this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          this.SAMLService();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  loginbtn() {
    this.spinner.show();
    var objName = { BasfId: this.userName };
    this._appServ.getLoggedUserDataManual(objName).subscribe((result) => {
      this.spinner.hide();
      if (result != "") {
        this.loggeduserObj.ParamName = result[0].BasfId;
        this.loggeduserObj.ParamEnName = "";
        this.loggeduserObj.ParamMail = result[0].Email;
        this.loggeduserObj.ParamFirstName = result[0].FirstName;
        this.loggeduserObj.ParamLastName = result[0].LastName;
        this.loggeduserObj.ParamManagerName = result[0].ManagerName;
        this.loggeduserObj.ParamManagerMail = result[0].ManagerMail;
        this.loggeduserObj.ParamManagerId = result[0].ManagerID;
        this.loggeduserObj.ParamEmployeeId = result[0].EmployeeId;
        sessionStorage.setItem('ACSuserName', result[0].BasfId);

        sessionStorage.setItem("loggeduserObj", JSON.stringify(this.loggeduserObj));
        this._appServ.SetUserObj(this.loggeduserObj);
        this._appServ.setUserName(result[0].BasfId);
      }
    },
      (error) => {
        if (error.status == 401)
          this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
}
