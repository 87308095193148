import { Component, OnInit } from '@angular/core';
import { AppServicesService } from '../services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-login-manual',
  templateUrl: './login-manual.component.html',
  styleUrls: ['./login-manual.component.css']
})
export class LoginComponentManual implements OnInit {
  userName = "";
  pass = "";
  loggeduserObj: any = {
    ParamName: '',
    ParamEnName: '',
    ParamMail: '',
    ParamFirstName: '',
    ParamLastName: '',
    ParamManagerName: '',
    ParamManagerMail: '',
    ParamManagerId: '',
    ParamEmployeeId: ''
  };
  ACSuser: any;
  ACSuserDep: any;
  ACSuserparam: any;
  ACSManualUser: boolean = false;
  constructor(private _commonService: SharedService, private spinner: NgxSpinnerService, private _appServ: AppServicesService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    console.log('hostname ', window.location.hostname);
    if (window.location.hostname != "acs-qa.basf.com") {
      this.router.navigate(['/login']);
    }
    this.ACSManualUser = true;    
    sessionStorage.setItem("UnAuthorizeMsg", "Http failure, as the request is Unauthorized");
  }
  
  loginbtn() {
    this.spinner.show();
    //var objName = { BasfId: this.userName };

    var objName = { BasfId: this.userName, Department: null, Location: null, FirstName: null, LastName: null, Email: null, Password: this.pass };

    this._appServ.getLoggedUserDataManual(objName).subscribe((result) => {      
      this.spinner.hide();
      if (result == "Please Enter the correct password!") {
        this._commonService.ToastMsg("Error", "Wrong Password", result);
        return;
      }
      if (result != "") {
        var JWTDetail = result["Table1"];        
        sessionStorage.setItem("AuthToken", JWTDetail[0].JWT);
        var UserDetail = result["Table2"];
        if (UserDetail[0] != "") {
          sessionStorage.setItem('ACSuserName', UserDetail[0].BasfId);          
          this.loggeduserObj.ParamName = UserDetail[0].BasfId;
          this.loggeduserObj.ParamEnName = "";
          this.loggeduserObj.ParamMail = UserDetail[0].Email;
          this.loggeduserObj.ParamFirstName = UserDetail[0].FirstName;
          this.loggeduserObj.ParamLastName = UserDetail[0].LastName;
          this.loggeduserObj.ParamManagerName = UserDetail[0].ManagerName;
          this.loggeduserObj.ParamManagerMail = UserDetail[0].ManagerMail;
          this.loggeduserObj.ParamManagerId = UserDetail[0].ManagerID;
          this.loggeduserObj.ParamEmployeeId = UserDetail[0].EmployeeId;
          this.loggeduserObj.ParamRoleID = UserDetail[0].RoleID;
          this.loggeduserObj.DisableBUKRS = UserDetail[0].DisableBUKRS;
          if (UserDetail[0].DisableBUKRS == undefined)
            this.loggeduserObj.DisableBUKRS = true;

          if (UserDetail[0].RoleID == "1")
            document.getElementById("lnkAdmin").style.display = "block";

          this._appServ.SetUserObj(this.loggeduserObj);
          this._appServ.setUserName(UserDetail[0].BasfId);
          sessionStorage.setItem("loggeduserObj", JSON.stringify(this.loggeduserObj));
        }
      }
    },
      (error) => {
        if (error.status == 401)
          this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
}
