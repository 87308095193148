import { Component, OnInit } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { DataSharingService } from '../shared/data-sharing.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServicesService } from '../services/app-services.service';
import { debug } from 'util';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})
export class HomePageComponent implements OnInit {

  bgColor = "#004890";
  currPage = "home";
  showSearchResults: boolean = false;
  searchResultsCols: any[];
  totalRows: any = 50;
  loggeduserObj: any = {
    ParamName: '',
    ParamEnName: '',
    ParamMail: '',
    ParamFirstName: '',
    ParamLastName: '',
    ParamManagerName: '',
    ParamManagerMail: '',
    ParamManagerId: '',
    ParamEmployeeId: ''
  };
  userName: any;
  tempSrchData: any;
  isReport: boolean = true;
  strEmail: any;
  constructor(private _commonService: SharedService, private spinner: NgxSpinnerService, private _shareData: DataSharingService, private route: Router, private appService: AppServicesService, private router: ActivatedRoute) { }

  ngOnInit() {
    this.userName = sessionStorage.getItem('ACSuserName');
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null) {
      this.route.navigate(['/login']);
    }
    this.spinner.show();
      this.appService.getEmail().subscribe((result) => {
        this.spinner.hide();
        this.strEmail = result;        
      },
        (error) => {
          this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    this.searchResultsCols = [
      { field: 'fullname', header: 'Name' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'email', header: 'Email ID' },
      { field: 'managerName', header: 'Manager Name' }
    ];
    let argData = null;
    this._shareData.sendSharedData(argData);
  }

  receiveSearchData(data: any) {
    if (data.currentPage === this.currPage) {
      this.bgColor = "white";
    }
    this.getSearchData(data);
  }

  getSearchData(data: any) {
    this.spinner.show();
    this.appService.getSearchResults(data).subscribe((result) => {
      this.spinner.hide();
      this.tempSrchData = result;
      if (this.tempSrchData.length > 0)
        this.isReport = false;
      else
        this.isReport = true;
    },
      (error) => {
        if (error.status == 401) {
          this.appService.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
    this.showSearchResults = true;
  }

  generateReport() {
    this.spinner.show();
    let sheetCols = this.searchResultsCols;
    this._commonService.exportAsExcelFile(this.tempSrchData, "Generate-Report", "Sheet1", sheetCols);
    this._commonService.ToastMsg("Success", "Success", "Downloaded Successfully");
    this.spinner.hide();
  }

  openNewTab() {
    window.open("http://google.com", "_blank");
  }
  openFI() {
    window.open("https://basf.sharepoint.com/:w:/r/sites/p2p/_layouts/15/WopiFrame.aspx?sourcedoc=%7BAFE26ADD-29EC-4849-9279-77A45EB7B009%7D&file=FAQs.docx&action=default", "_blank");
  }
  openPR() {
    window.open("https://basf.sharepoint.com/sites/projecttraining/pr-approval-en/SitePages/PR---Approval.aspx", "_blank");
  }
  navigateToViewApproval(userData: any) {    
    this._shareData.sendSharedData(userData);
    this.route.navigate(["/approval"]);
  }
}
