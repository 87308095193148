import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { ToastrService } from 'ngx-toastr';
const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

const EXCEL_EXTENSION = '.xlsx';

@Injectable({
  providedIn: 'root'
})
export class SharedService {

  constructor(private toastr: ToastrService) { }

  public exportAsExcelFile(json: any[], excelFileName: string, sheetName: string, columnValues: any[]): void {
    //columnValues = columnValues.filter(e => e.visibility == true);
    var fieldValues: any[] = columnValues.map(field => field.field);
    var headerValues: string[] = columnValues.map(header => header.header);
    var displayedJson: any[] = [];
    if (json != null && json.length > 0) {
      for (let originalValue of json) {
        var jsonObject: any = {};
        for (let fieldValue of fieldValues) {
          jsonObject[fieldValue] = originalValue[fieldValue];
        }
        var jsonString = JSON.stringify(jsonObject);
        displayedJson.push(JSON.parse(jsonString));
      }
      const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(displayedJson);
      var range = XLSX.utils.decode_range(worksheet['!ref']);
      for (var C = range.s.c; C <= range.e.c; ++C) {
        var address = XLSX.utils.encode_col(C) + "1"; // <-- first row, column number C
        if (!worksheet[address]) continue;
        worksheet[address] = {
          v: headerValues[C],
          t: 's',
          s: {
            alignment: { textRotation: 90 },
            font: { bold: true }
          }
        };
      }
      XLSX.utils.sheet_add_aoa(worksheet, [[]], { origin: -1 });
      const workbook: XLSX.WorkBook = { Sheets: { [sheetName]: worksheet }, SheetNames: [sheetName] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, excelFileName);
    }


  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });

    FileSaver.saveAs(data, fileName + EXCEL_EXTENSION);
  }
  public ToastMsg(ToastType, FirstMessage, UserMessage) {
    if (FirstMessage != null && FirstMessage != '' && FirstMessage != undefined) {
      if (ToastType == 'Error') {
        this.toastr.error(UserMessage,FirstMessage,  {
          closeButton: true,
          enableHtml: true
        });
      }
      else if (ToastType == 'Warning') {
        this.toastr.warning(UserMessage,FirstMessage,  {
          closeButton: true,
          enableHtml: true
        });
      }
      else if (ToastType == 'Success') {
        this.toastr.success(UserMessage,FirstMessage, {
          closeButton: true,
          enableHtml: true
        });
      }
    }
  }
  public ExcelDateToJSDate(date) {
    return new Date(Math.round((date - 25569) * 86400 * 1000));
  }
  public reorder(order, obj) {
    typeof order === 'string' && (order = order.split(/\s*,\s*/));
    return order.reduce(function (rslt, prop) {
      rslt[prop] = obj[prop];
      return rslt;
    }, {});
  }

}
