import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {

  @Input() currentPage;
  @Output() sendSearchData = new EventEmitter();

  searchKeyword = "";
  firstName = "";
  lastName = "";

  searchData: any;

  constructor() { }

  ngOnInit() {
    
  }

  startSearch() {
    this.searchData = {
      "searchKeyword": this.searchKeyword,
      "firstName": this.firstName,
      "lastName": this.lastName,
      "currentPage": this.currentPage,
    }    
    this.sendSearchData.emit(this.searchData);
    this.searchKeyword = "";
    this.firstName = "";
    this.lastName = "";
  }

}
