import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    let loggeduserObj = JSON.parse(sessionStorage.getItem("loggeduserObj"));
    var RoleId = "";
    if (loggeduserObj != null && loggeduserObj != undefined && loggeduserObj != "undefined") {
      RoleId = loggeduserObj.ParamRoleID;
    }
    if (RoleId == "1")
      document.getElementById("lnkAdmin").style.display = "block";
  }
}
