import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs/internal/Subscription';
import { ActivatedRoute, Router } from '@angular/router';

import { forkJoin, Observable } from 'rxjs';  // RxJS 6 syntax

@Injectable({
  providedIn: 'root'
})
export class AppServicesService {
  public userName = "";

  getUserName() {
    return this.userName;
  }
  
  orgUnits = [
    { label: "Org Unit1", value: "orgUnit1" },
    { label: "Org Unit2", value: "orgUnit2" }
  ];

  businessUnits = [
    { label: "Business Unit1", value: "businessUnit1" },
    { label: "Business Unit2", value: "businessUnit2" }
  ];


  private searchedResults: any;

  constructor(private _http: HttpClient, private router: Router) { }
  SaveCategory(CategoryObj: any) {
    return this._http.post(environment.ManageCategory, JSON.stringify(CategoryObj)).pipe(map((response) => response));
  }
  SaveCompany(CompanyObj: any) {
    return this._http.post(environment.ManageCompany, JSON.stringify(CompanyObj)).pipe(map((response) => response));
  }
  SaveApprovalCategoryException(objData: any) {
    return this._http.post(environment.SaveApprovalCategoryException, JSON.stringify(objData)).pipe(map((response) => response));
  }

  UpdateIpcApprovalReject(objData: any) {
    return this._http.post(environment.UpdateIpcApprovalReject, JSON.stringify(objData)).pipe(map((response) => response));
  }

  UpdateIPCCostLimit(objData: any) {
    return this._http.post(environment.UpdateIPCCostLimit, JSON.stringify(objData)).pipe(map((response) => response));
  }

  SaveIPCCostLimitChangeRequests(objData: any) {
    return this._http.post(environment.SaveIPCCostLimitChangeRequests, JSON.stringify(objData)).pipe(map((response) => response));
  }

  getCategoryException(searchData: any) {
    return this._http.post(environment.getCategoryException, JSON.stringify(searchData)).pipe(map((response) => response));
  }
  getSearchResults(searchData: any) {
    return this._http.post(environment.getSearchResults, JSON.stringify(searchData)).pipe(map((response) => response));

  }
  getReportExcel(searchData: any) {
    return this._http.post(environment.getReportExportExcelData, JSON.stringify(searchData)).pipe(map((response) => response));

  }
  getSelfLimit(IdData: any) {
    let response1 = this._http.post(environment.getLimit, JSON.stringify(IdData)).pipe(map((response) => response));
    let response2 = this._http.post(environment.getIndividualLimit, JSON.stringify(IdData)).pipe(map((response) => response));
    return forkJoin([response1, response2]);
  }
  getEmail() {
    return this._http.get(environment.HomeEmail).pipe(map((response) => response));
  }
  getRoles() {
    return this._http.get(environment.getRoles).pipe(map((response) => response));
  }
  getAppUsers() {
    return this._http.get(environment.getAppUsers).pipe(map((response) => response));
  }
  getCategories() {
    return this._http.get(environment.getAllCategories).pipe(map((response) => response));
  }
  getCompanies(){
    return this._http.get(environment.getCompanies).pipe(map((response) => response));
  }
  getLimits(IdData: any) {
    let response1 = this._http.post(environment.getLimit, JSON.stringify(IdData)).pipe(map((response) => response));
    let response2 = this._http.post(environment.getIndividualLimit, JSON.stringify(IdData)).pipe(map((response) => response));
    return forkJoin([response1, response2]);
  }
  getCompany(IdData: any) {
    let response1 = this._http.get(environment.getCompanies).pipe(map((response) => response));
    let response2 = this._http.post(environment.getIndividualCompany, JSON.stringify(IdData)).pipe(map((response) => response));
    return forkJoin([response1, response2]);
  }
  getCategoryExceptionApproval(dataObj: any) {
    return this._http.post(environment.getCategoryExceptionApproval, JSON.stringify(dataObj)).pipe(map((response) => response));
  }

  getIpccostLimitChangeRequests(dataObj: any) {
    return this._http.post(environment.getIpccostLimitChangeRequests, JSON.stringify(dataObj)).pipe(map((response) => response));
  }

  getipcccostlimitcategory(){
    return this._http.get(environment.getipcccostlimitcategory).pipe(map((response) => response));
  }

  getManagers(EmployeeObj: any) {
    let response1 = this._http.post(environment.getManagers, JSON.stringify(EmployeeObj)).pipe(map((response) => response));
    let response2 = this._http.post(environment.getGrantors, JSON.stringify(EmployeeObj)).pipe(map((response) => response));
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2]);
  }
  getApprovers(EmployeeObj: any) {
    return this._http.post(environment.getApprover, JSON.stringify(EmployeeObj)).pipe(map((response) => response));
  }
  getFilterEmployee(EmployeeName: any) {
    return this._http.post(environment.getFilterEmployee, JSON.stringify(EmployeeName)).pipe(map((response) => response));
  }
  getGrantorsData(EmployeeId: any) {
    return this._http.post(environment.getGrantors, JSON.stringify(EmployeeId)).pipe(map((response) => response));
  }
  getUserInfoData1(userId: any) {
    let response1 = this._http.post(environment.getUserInfoData, JSON.stringify(userId)).pipe(map((response) => response));
    let response2 = this._http.post(environment.getApprovalData, JSON.stringify(userId)).pipe(map((response) => response));
    // Observable.forkJoin (RxJS 5) changes to just forkJoin() in RxJS 6
    return forkJoin([response1, response2]);
  }
  getSubordinates(UserID: any) {
    return this._http.post(environment.getSubordinates, JSON.stringify(UserID)).pipe(map((response) => response));
  }
  Surrogate(RequestObj: any) {
    return this._http.post(environment.Surrogate, JSON.stringify(RequestObj)).pipe(map((response) => response));
  }


  getLoggedUserData(UserName: any) {
    return this._http.post(environment.getLoggedUserData, JSON.stringify(UserName)).pipe(map((response) => response));
  }

  getLoggedUserDataManual(UserName: any) {
    return this._http.post(environment.getLoggedUserDataManual, JSON.stringify(UserName)).pipe(map((response) => response));
  }
  
  getSAMLServiceAuthorize() {
    let ACSDataLoading = false;  
    if (window.location.hostname == "acs-qa.basf.com") {
      if (sessionStorage.getItem("ACSSystemLocation") == "login-manual") {
        this.router.navigate(['/login-manual']);
        return;
      }
    }
    
    this.getDataLoadingStatus().subscribe((result) => {
      ACSDataLoading = result[0]["IsDataLoading"];
      if(!ACSDataLoading){        
        this.getSAMLService().subscribe((result) => {
          window.location.href = result.toString();
          return "success";
        },
          (error) => {
            return "error";
          });
      }
    });
    
  }
  getSAMLService() {
    return this._http.get(environment.getSAMLServiceURL).pipe(map((response) => response));
  }
  getDataLoadingStatus(){
    return this._http.get(environment.getLoadingStatus).pipe(map((response) => response));
  }

  getReports(searchData: any) {
    return this._http.post(environment.getReportData, JSON.stringify(searchData)).pipe(map((response) => response));
  }

  getUsers(searchData: any) {
    return this._http.post(environment.getFilterEmployee, JSON.stringify(searchData)).pipe(map((response) => response));
  }

  getOrgUnits() {
    return this.orgUnits;
  }

  getReportFiltersData() {
    return this._http.get(environment.getReportFilterOpts).pipe(map((response) => response));
  }

  getBusinessUnits() {
    return this.businessUnits;
  }

  saveUsers(userInfo: any) {
    return this._http.post(environment.ManageUsers, JSON.stringify(userInfo)).pipe(map((response) => response));
  }

  RequestModification(RequestObj: any) {
    return this._http.post(environment.RequestModification, JSON.stringify(RequestObj)).pipe(map((response) => response));
  }
  RequestModificationSurrogate(RequestObj: any) {
    return this._http.post(environment.Surrogate, JSON.stringify(RequestObj)).pipe(map((response) => response));
  }


  deleteUser(userInfo: any) {

  }
  setUserName(user) {
    this.userName = user;
    if (this.userName != null && this.userName != "") {
      this.router.navigate(['/home']);
    }
  }
  private OrgStructureData: any;
  setOrgStructureList(data: any) {
    this.OrgStructureData = data;
  }
  getOrgStructureList() {
    return this.OrgStructureData;
  }

  private UserObjData: any;
  SetUserObj(data: any) {
    this.UserObjData = data;
  }
  GetUserObj() {
    return this.UserObjData;
  }

  getTotalRowsPerPage(event, totalRecords) {
    let calcRows = event.first + event.rows;
    if (calcRows > totalRecords) {
      return totalRecords;
    }
    return calcRows;
  }

  private reportsFormData: any;
  setReportFormData(data: any) {
    this.reportsFormData = data;
  }
  getReportFormData() {
    return this.reportsFormData;
  }


  setSearchedData(value: any) {
    this.searchedResults = value;
  }
  getSearchedData() {
    return this.searchedResults;
  }

  getIPcostLimits() {
    return this._http.get(environment.getIPCostLimits).pipe(map((response) => response));
  }

  saveIPCostLimit(ipCostInfo: any) {
    return this._http.post(environment.ManageIPCostLimt, JSON.stringify(ipCostInfo)).pipe(map((response) => response));
  }
}
