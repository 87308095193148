import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataSharingService } from '../shared/data-sharing.service';
import { AppServicesService } from '../services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { SharedService } from '../shared/shared.service';
import { AutoComplete } from 'primeng/autocomplete';
import { CalendarModule } from 'primeng/calendar';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';




@Component({
  selector: 'app-approval',
  templateUrl: './approval.component.html',
  styleUrls: ['./approval.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ApprovalComponent implements OnInit {
  @ViewChild('autoCompleteSelfObject') private autoCompleteSelfObject: AutoComplete;
  @ViewChild('autoCompleteSubordinateObject') private autoCompleteSubordinateObject: AutoComplete;
  @ViewChild('autoCompleteSomeoneNameObject') private autoCompleteSomeoneNameObject: AutoComplete;
  @ViewChild('autoCompleteSomeoneElseObject') private autoCompleteSomeoneElseObject: AutoComplete;
  @ViewChild('autoCompleteSurrogateOtherObject') private autoCompleteSurrogateOtherObject: AutoComplete;
  @ViewChild('autoCompleteSurrogateToObject') private autoCompleteSurrogateToObject: AutoComplete;
  @ViewChild('autoCompleteOtherObject') private autoCompleteOtherObject: AutoComplete;

  isReport: boolean = true;
  isBUKRS: boolean = false;
  currPage = "approval";
  ApprovalExport = [];
  tempTreeData = [];
  userData: any;
  selectedNode: any;
  orgdata: any;
  childData = [];
  tempObj = null;
  tempChild: any;
  tempData = [];
  searchResultsCols: any[];
  tempSrchData: any;
  searchRequest: any;
  approverName = "";
  employeeName = "";
  orgUnit = "";
  title = "";
  PersonalNumber = "";
  showTreeDiv: Boolean = true;
  showTablist: Boolean = false;
  showSearchResults: boolean = false;
  activeTab = "";
  selectedCategory: any;
  EmployeeUserId = "";

  Categories: any;
  Limits: any;
  CompanyCodes: any = [];
  SelfGrantorsOpt: any;
  mulitpleGrantorsOpt: any;
  OthersMailOpt = '';
  Others_self: any;
  LimitsOpt: any;
  CompanyOpt: any = [];
  selfTabData: any = {
    EmployeeName: '',
    EmployeeEmail: '',
    RequestType: '',
    EmployeeId: '',
    CategoryId: '',
    CatergoryValue: '',
    GrantorID: '',
    GrantorSelID: '',
    GrantorName: '',
    GrantorEmail: '',
    ManagerID: '',
    ManagerName: '',
    ManagerEmail: '',
    ExistingLimit: '',
    OthersID: '',
    OthersMail: '',
    OthersName: '',
    LimitID: '',
    LimitValue: '',
    CompanyCodes: [],
    CompanyCodesStr: '',
    SentTO: '',
    SelectedGrantors: '',
    SelectedSelGrantors: '',
    CCEmailList: '',
    selfGrantingStatus: 'No',
    selfGrantor: 'Yes',
    CreatedBy: ''
  };

  
  SubOrdinateOpt: any;
  SubordinateGrantorsOpt: any;
  mulitpleSubordinateGrantorsOpt: any;
  SubOrdinateTabData: any = {
    EmployeeName: '',
    EmployeeEmail: '',
    RequestType: '',
    EmployeeId: '',
    EmployeeSelId: '',
    CategoryId: '',
    CatergoryValue: '',
    GrantorID: '',
    GrantorSelID: '',
    GrantorName: '',
    OthersID: '',
    OthersMail: '',
    GrantorEmail: '',
    ManagerID: '',
    ManagerName: '',
    ManagerEmail: '',
    ExistingLimit: '',
    LimitID: '',
    LimitValue: '',
    CompanyCodes: [],
    CompanyCodesStr: '',
    SentTO: '',
    SelectedGrantors: '',
    SelectedSelGrantors: '',
    CCEmailList: '',
    subordinateGrantingStatus: 'No',
    subordinateGrantor: 'Yes',
    CreatedBy: ''
  };

  SomeoneElseOpt: any;
  SomeoneElseGrantorsOpt: any;
  mulitpleSomeoneElseGrantorsOpt: any;
  SomeoneElseManagerID: '';
  SomeoneElseManagerName: any;
  SomeoneElseManagerEmail: '';
  SomeoneElseTabData: any = {
    EmployeeName: '',
    EmployeeEmail: '',
    EmployeeId: '',
    EmployeeSelId: '',
    RequestType: '',
    CategoryId: '',
    CatergoryValue: '',
    GrantorID: '',
    GrantorSelID: '',
    GrantorName: '',
    GrantorEmail: '',
    ManagerID: '',
    ManagerName: '',
    ManagerEmail: '',
    ExistingLimit: '',
    OthersID: '',
    OthersMail: '',
    LimitID: '',
    LimitValue: '',
    CompanyCodes: [],
    CompanyCodesStr: '',
    SentTO: '',
    SelectedGrantors: '',
    SelectedSelGrantors: '',
    CCEmailList: '',
    SomeoneElseGrantingStatus: 'No',
    SomeoneElseGrantor: 'Yes',
    CreatedBy: ''
  };


  limit: any;
  grantingStatus = "";
  grantingStatus1 = "";
  grantorsFullName = "";
  grantorsEmail = "";
  surrogateStatus = "Yes";
  selectedEmployee = "";
  surrogateFullname = "";
  from: any;
  to: any;
  ApprovalGroup: any;
  ApprovalCategory: any;
  loggeduserObj: any;
  mulitpleSurrogateOpt: any;
  mulitpleSurrogateToOpt: any;
  SurrogateTabData: any = {
    SelfSurrogate: ' ',
    surrogateOtherMail: '',
    surrogateToMail: '',
    from: '',
    to: '',
    surrogateOtherName: '',
    SurrogateOtherId: '',
    surrogateOtherSelId: '',
    surrogateToSelId: '',
    SurrogatetoId: '',
    surrogateToName: '',
    LoggedInUserId: '',
    LoggedInUserEmail: '',
    LoggedInUser: '',
    FromDate: '',
    ToDate: '',
    CCEmailList: ''
  }
  userName: any;

  NewIpcCategory = {
    EmployeeId: '',
    CategoryId: '',
    RequestedIPCCostLimitId: '',
    RequestedCostLimit: '',
    CreatedBy: ''
  }

  constructor(private _commonService: SharedService, private spinner: NgxSpinnerService, private _dataServ: DataSharingService, private _appServ: AppServicesService, private router: Router) { }

  getOrgInfo(userId) {
    
    this.tempData = [];
    this.tempChild = null;
    this.tempObj = null;
    var objUserId = { Id: userId };
    this.spinner.show();
    this._appServ.getUserInfoData1(objUserId).subscribe((result) => {
      
      this.spinner.hide();
      this.ApprovalGroup = result[1];
      this.ApprovalCategory = result[1];
      this.ApprovalGroup = this.ApprovalGroup.reduce((unique, o) => {
        if (!unique.some(obj => obj.GroupName === o.GroupName)) {
          unique.push(o);
        }
        return unique;
      }, []);
      this.ApprovalExport = [];
      var objApprovalExport = {};
      for (var i = 0; i < this.ApprovalCategory.length; i++) {
        if (i == 0) {
          objApprovalExport["Employee Name"] = `${this.ApprovalCategory[i].FirstName} ${this.ApprovalCategory[i].LastName}`;
          objApprovalExport["CCHR CCPN"] = this.PersonalNumber;
          objApprovalExport["Manager Name"] = this.approverName;
          objApprovalExport["Organization Unit"] = this.orgUnit;
          objApprovalExport["Title"] = this.title;
        }
        if (this.ApprovalCategory[i].CostLimit != null)
          objApprovalExport[`${this.ApprovalCategory[i].GroupName} - ${this.ApprovalCategory[i].CategoryName}`] = `${this.ApprovalCategory[i].CostLimit}[${this.ApprovalCategory[i].CurrencySymbol}]`;
        else
          objApprovalExport[`${this.ApprovalCategory[i].GroupName} - ${this.ApprovalCategory[i].CategoryName}`] = `${this.ApprovalCategory[i].CostLimitDesc}[${this.ApprovalCategory[i].CurrencySymbol}]`;
      }
      this.ApprovalExport.push(objApprovalExport);
      this.orgdata = result[0];
      this.constructParentTree(this.orgdata[0]);
      var objSelectNode = [{
        id: this.EmployeeUserId, label: this.userData.firstName
      }];
      this.selectedNode = objSelectNode[0];
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  GenerateApprovalReport() {
    const worksheet = XLSX.utils.json_to_sheet(this.ApprovalExport);
    const workbook = { Sheets: { 'ApprovalLimit': worksheet }, SheetNames: ['ApprovalLimit'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    this.saveAsExcelFile(excelBuffer, "Approval_Limit_Report");
  }
  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }
  ngOnInit() {
    this.userName = sessionStorage.getItem('ACSuserName');
    console.log('userName ', this.userName);
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null) {
      this.router.navigate(['/login']);
    }
    this.loggeduserObj = JSON.parse(sessionStorage.getItem("loggeduserObj"));
    this.isBUKRS = JSON.parse(this.loggeduserObj.DisableBUKRS);    
    this.orgdata = null;
    this.tempData = [];
    this.userData = this._dataServ.receiveSharedData();
    let argData = null;
    this._dataServ.sendSharedData(argData);
    if (this.userData != undefined && this.userData != null) {
      this.EmployeeUserId = this.userData.EmployeeId;
      this.getOrgInfo(this.EmployeeUserId);
      this.employeeName = this.userData.fullname;
      this.approverName = this.userData.managerName;
      this.orgUnit = this.userData.OrganizationName;
      this.title = this.userData.Title;
      this.PersonalNumber = this.userData.PersonalNumber;
    }
    this.searchResultsCols = [
      { field: 'fullname', header: 'Name' },
      { field: 'firstName', header: 'First Name' },
      { field: 'lastName', header: 'Last Name' },
      { field: 'email', header: 'Email ID' },
      { field: 'managerName', header: 'Manager Name' }
    ];
  }

  receiveSearchData(data: any) {
    if (data.currentPage === this.currPage) {
      this.getSearchData(data);
    }
  }
  generateReport() {
    this.spinner.show();
    let sheetCols = this.searchResultsCols;
    this._commonService.exportAsExcelFile(this.tempSrchData, "Generate-Report", "Sheet1", sheetCols);
    this._commonService.ToastMsg("Success", "Success", "Downloaded Successfully");
    this.spinner.hide();
  }
  getSearchData(data: any) {
    this.tempSrchData = null;
    this.spinner.show();
    this._appServ.getSearchResults(data).subscribe((result) => {
      this.tempSrchData = result;
      if (this.tempSrchData.length > 0)
        this.isReport = false;
      else
        this.isReport = true;
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
    this.showSearchResults = true;
    this.showTreeDiv = false;
    this.showTablist = false;
  }
  constructParentTree(rData) {
    var TreeClass = "pi pi-user";
    if (rData.Id == this.EmployeeUserId) {
      TreeClass = "pi pi-user treeActive";
    }

    this.tempChild = {
      id: rData.Id,
      label: rData.Name,
      children: [],
      expandedIcon: TreeClass,
      collapsedIcon: TreeClass,
      data: "ChildTree",
      expanded: true
    }
    if (this.tempObj != null) {
      this.tempChild.children.push(this.tempObj);
    }
    else {
      this.orgdata[0].Children.forEach(child => {
        var ChildTree = "pi pi-user";
        if (child.Id == this.EmployeeUserId) {
          ChildTree = "pi pi-user treeActive";
        }
        let tChild = {
          id: child.Id,
          label: child.Name,
          children: [],
          expandedIcon: ChildTree,
          collapsedIcon: ChildTree,
          expanded: true
        }
        this.tempChild.children.push(tChild);
      });
    }

    this.tempObj = this.tempChild;
    this.tempChild = {};
    var IsParent = !!rData.Parent;
    if (IsParent) {
      if (rData.Parent.length > 0) {
        this.constructParentTree(rData.Parent[0]);
      } else {
        this.tempData.push(this.tempObj);
        return this.tempObj;
      }
    }
    else {
      this.tempData.push(this.tempObj);
      return this.tempObj;
    }
  }
  getUserInfo(event) {
    this.getOrgInfo(this.selectedNode.id);
    this.EmployeeUserId = this.selectedNode.id;
    this.getApprovalDetails();
  }
  getApprovalDetails() {
    //this.spinner.show();
    var objSubId = { ID: this.EmployeeUserId };
    this._appServ.getApprovers(objSubId).subscribe((result) => {
      this.employeeName = result[0]["FirstName"] + " " + result[0]["LastName"];
      this.approverName = result[0]["ManagerName"];
      this.orgUnit = result[0]["OrganizationName"];
      this.title = result[0]["Title"];
      this.PersonalNumber = result[0]["PersonalNumber"];
      //this.spinner.hide();
    },
      (error) => {
        //this.spinner.hide();
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
      });
  }
  getApprovalData(event) {
    this._dataServ.sendSharedData(event);
    this.userData = this._dataServ.receiveSharedData();
    this.EmployeeUserId = this.userData.EmployeeId;
    let argData = {};
    this._dataServ.sendSharedData(argData);
    this.orgdata = null;
    this.ApprovalGroup = null;
    this.ApprovalCategory = null;
    this.tempData = [];
    this.tempChild = {};
    this.tempObj = null;
    if (this.userData != undefined && this.userData != null) {
      this.getOrgInfo(this.EmployeeUserId);
      this.employeeName = this.userData.fullname;
      this.approverName = this.userData.managerName;
      this.orgUnit = this.userData.OrganizationName;
      this.title = this.userData.Title;
      this.PersonalNumber = this.userData.PersonalNumber;
    }
    this.showTreeDiv = true;
    this.showTablist = false;
    this.showSearchResults = false;
  }
  deleteParent(ParentRemoves) {
    delete ParentRemoves[0].Parent;
  }
  /* ******************** Self Tab Start ******************* */
  
  GetLimitSelf(event) {
    this.selfTabData.CatergoryValue = event.target.options[event.target.options.selectedIndex].text;
    if (!this.selfTabData.CategoryId) {
      this.limit = '';
      this.selfTabData.CatergoryValue = "";
      this.Limits = [];
      this.selfTabData.LimitID = "";
      return false;
    }
    this.spinner.show();
    var ObjData = { CategoryId: this.selfTabData.CategoryId, Id: this.loggeduserObj.ParamEmployeeId };
    this._appServ.getLimits(ObjData).subscribe((result) => {
      debugger;
      this.LimitsOpt = result[0];
      if (this.LimitsOpt.length > 0) {
        this.Limits = result[0];
      }
      else {
        this.Limits = [];
      }
      if (result[1] != "") {
        var LimitId = result[1][0];
        this.selfTabData.LimitID = LimitId.CostLimitId;
        this.selfTabData.LimitValue = this.Limits.filter(function(element) { return element.CostLimitId === result[1][0].CostLimitId;})[0].CostLimitValue;
      }
      else
        this.selfTabData.LimitID = "";
      this.spinner.hide();
    },
      (error) => {
        this.spinner.hide();
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
      });
      var ObjCompData = { GroupId: this.selfTabData.CategoryId, Id: this.loggeduserObj.ParamEmployeeId };
      this._appServ.getCompany(ObjCompData).subscribe((result) => {
        debugger;
        this.CompanyOpt = result[0];
        if (this.CompanyOpt.length > 0) {
          this.CompanyCodes = result[0];
        }
        else {
          this.CompanyCodes = [];
        }
        if (result[1] != "") {
          this.selfTabData.CompanyCodes = result[1];
        }
        else
        this.selfTabData.CompanyCodes = [];
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
        });
  }
  onselfLimitChange(event) {
    this.selfTabData.LimitValue = event.target.options[event.target.options.selectedIndex].text;
  }
  onSelfTabCompanyCodeSelect(item: any) {
    console.log(item);
  }
  onSelfTabCompanyCodeSelectAll(items: any) {
    console.log(items);
  }
  getSelfTab() {
    if (this.Categories == undefined || this.Categories == '' || this.Categories == null) {
      this.spinner.show();
      this._appServ.getCategories().subscribe((result) => {
        this.spinner.hide();
        this.Categories = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }

    var ObjData = { Id: this.loggeduserObj.ParamEmployeeId };
    if (this.SelfGrantorsOpt == undefined || this.SelfGrantorsOpt == '' || this.SelfGrantorsOpt == null) {
      this.spinner.show();
      this._appServ.getGrantorsData(ObjData).subscribe((result) => {
        this.spinner.hide();
        let resultSet = result;
        this.GrantorIdEmail(resultSet["SearchID"]);
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  multipleGrantorIdEmail(MultipleGrantor) {
    for (var i = 0; i < MultipleGrantor.length; i++) {
      MultipleGrantor[i].IdEmail = MultipleGrantor[i].EmployeeId + ',' + MultipleGrantor[i].Email;
    }
    this.mulitpleGrantorsOpt = MultipleGrantor;
  }
  OnSelectedSelfAnyone(event) {
    this.SelfEmployeeEmail(this.selfTabData.SelectedSelGrantors);
  }
  OnUnSelectedSelfAnyone(event) {
    this.SelfEmployeeEmail(this.selfTabData.SelectedSelGrantors);
  }
  filterSelfAnyone(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.mulitpleGrantorsOpt = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  filterSelfOther(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.Others_self = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  onSelectedSelfOther(event: any) {
    this.selfTabData.OthersName = this.OthersMailOpt["fullName"];
    this.selfTabData.OthersID = '';
    this.selfTabData.OthersMail = '';
    if (event != undefined) {
      let temp = event.Details;
      temp = temp.split(', ');
      this.selfTabData.OthersID = temp[0];
      this.selfTabData.OthersMail = temp[1];
    }

  }
  GrantorIdEmail(GrantorOPT) {
    for (var i = 0; i < GrantorOPT.length; i++) {
      GrantorOPT[i].IdEmail = GrantorOPT[i].Id + ',' + GrantorOPT[i].Email;
    }
    this.SelfGrantorsOpt = GrantorOPT;
  }
  SelfGrantorEmail(event) {
    var EmailId = event;
    EmailId = EmailId.split(",");
    this.selfTabData.GrantorEmail = EmailId[1];
  }

  SelfEmployeeEmail(event) {
    this.selfTabData.CCEmailList = "";
    this.selfTabData.SelectedGrantors = "";
    for (var i = 0; i < event.length; i++) {
      let GrantorID = event[i].Details;
      GrantorID = GrantorID.split(', ');
      if (i == 0) {
        this.selfTabData.CCEmailList = GrantorID[1];
        this.selfTabData.SelectedGrantors = GrantorID[0];
      }
      else {
        this.selfTabData.CCEmailList += ";" + GrantorID[1];
        this.selfTabData.SelectedGrantors += "," + GrantorID[0];
      }
    }
  }
  ToastrWarning(Title, Message) {
    this._commonService.ToastMsg("Warning", Title, Message);
  }
  OnselfCompanyChange(event){
    debugger;
    this.selfTabData.CompanyCodes = event.value;    
    let companyCodes = '';
    for (var i = 0; i < event.value.length; i++) {
      console.log(event.value[i]);
      companyCodes+=event.value[i].CompanyDesc+'~^~';
    }
    console.log(companyCodes.substring(0,companyCodes.length-3));
    this.selfTabData.CompanyCodesStr = companyCodes.substring(0,companyCodes.length-3);
  }
  OnSubordinateCompanyChange(event){
    debugger;
    this.SubOrdinateTabData.CompanyCodes = event.value;    
    let companyCodes = '';
    for (var i = 0; i < event.value.length; i++) {
      console.log(event.value[i]);
      companyCodes+=event.value[i].CompanyDesc+'~^~';
    }
    console.log(companyCodes.substring(0,companyCodes.length-3));
    this.SubOrdinateTabData.CompanyCodesStr = companyCodes.substring(0,companyCodes.length-3);
  }
  OnSomeoneElseCompanyChange(event){
    debugger;
    this.SomeoneElseTabData.CompanyCodes = event.value;    
    let companyCodes = '';
    for (var i = 0; i < event.value.length; i++) {
      console.log(event.value[i]);
      companyCodes+=event.value[i].CompanyDesc+'~^~';
    }
    console.log(companyCodes.substring(0,companyCodes.length-3));
    this.SomeoneElseTabData.CompanyCodesStr = companyCodes.substring(0,companyCodes.length-3);
  }
  SubmitSelf() {
    this.selfTabData.CreatedBy = this.loggeduserObj.ParamName;
    this.selfTabData.EmployeeId = this.loggeduserObj.ParamEmployeeId;
    this.selfTabData.EmployeeName = this.loggeduserObj.ParamFirstName + " " + this.loggeduserObj.ParamLastName;
    this.selfTabData.RequestType = "Self";
    this.selfTabData.EmployeeEmail = this.loggeduserObj.ParamMail;
    if (this.selfTabData.CategoryId == "" || this.selfTabData.CategoryId == null || this.selfTabData.CategoryId == undefined) {
      document.getElementById("selfCategory").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.selfTabData.LimitValue == "" || this.selfTabData.LimitValue == null || this.selfTabData.LimitValue == undefined) {
      document.getElementById("selfLimit").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if(this.selfTabData.CategoryId != "5"){
      if (this.selfTabData.CompanyCodes == "" || this.selfTabData.CompanyCodes == null || this.selfTabData.CompanyCodes == undefined) {
        document.getElementById("selfCompany").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.selfTabData.selfGrantor == "No") {
      if (this.selfTabData.GrantorSelID == "" || this.selfTabData.GrantorSelID == null || this.selfTabData.GrantorSelID == undefined) {
        document.getElementById("selselfGrantorName").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      if (this.selfTabData.GrantorEmail == "" || this.selfTabData.GrantorEmail == null || this.selfTabData.GrantorEmail == undefined) {
        document.getElementById("selfGrantorEmail").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    else if (this.selfTabData.selfGrantor == "Other") {
      if (this.selfTabData.OthersMail == "" || this.selfTabData.OthersMail == null || this.selfTabData.OthersMail == undefined) {
        this.autoCompleteOtherObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.selfTabData.selfGrantingStatus == "Yes") {
      if (this.selfTabData.SelectedGrantors == "" || this.selfTabData.SelectedGrantors == null || this.selfTabData.SelectedGrantors == undefined) {
        this.autoCompleteSelfObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      if (this.selfTabData.CCEmailList == "" || this.selfTabData.CCEmailList == null || this.selfTabData.CCEmailList == undefined) {
        document.getElementById("selfMailCC").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.selfTabData.selfGrantor == "No") {
      if (this.selfTabData.GrantorSelID != "") {
        let GrantorID = this.selfTabData.GrantorSelID;
        GrantorID = GrantorID.split(',');
        this.selfTabData.GrantorID = GrantorID[0];
        this.selfTabData.SentTO = this.selfTabData.GrantorEmail;
      }
      else {
        this.selfTabData.GrantorID = "";
      }
    }
    else if (this.selfTabData.selfGrantor == "Yes") {
      this.selfTabData.ManagerID = this.loggeduserObj.ParamManagerId;
      this.selfTabData.ManagerName = this.loggeduserObj.ParamManagerName;
      this.selfTabData.ManagerEmail = this.loggeduserObj.ParamManagerMail;
      this.selfTabData.SentTO = this.selfTabData.ManagerEmail;
    }
    else {
      this.selfTabData.SentTO = this.selfTabData.OthersMail;
    }
    this.spinner.show();
    this._appServ.RequestModification(this.selfTabData).subscribe((result) => {
      this.spinner.hide();
      
      if (result == "Success") {
        this.NewIpcCategory.CategoryId = this.selfTabData.CategoryId;
        this.NewIpcCategory.EmployeeId = this.selfTabData.EmployeeId;
        this.NewIpcCategory.CreatedBy = this.selfTabData.EmployeeId;
        this.NewIpcCategory.RequestedCostLimit = this.selfTabData.LimitValue
        this.NewIpcCategory.RequestedIPCCostLimitId = this.selfTabData.LimitID;

        this._appServ.SaveIPCCostLimitChangeRequests(this.NewIpcCategory).subscribe((result) => {

          if (result == "Saved"){
            this._commonService.ToastMsg("Success", "Success!", "Request Modification has been sent successfully for Self");
            this.ClearSelfValues();
          }
        });
      }
      else {
        this.ToastrWarning("Warning!", result);
      }
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  ClearSelfValues() {
    this.selfTabData = {
      EmployeeName: '',
      EmployeeEmail: '',
      RequestType: '',
      EmployeeId: '',
      CategoryId: '',
      CatergoryValue: '',
      GrantorID: '',
      GrantorSelID: '',
      GrantorName: '',
      GrantorEmail: '',
      ManagerID: '',
      ManagerName: '',
      ManagerEmail: '',
      OthersID: '',
      OthersMail: '',
      ExistingLimit: '',
      LimitValue: '',
      LimitID: '',
      SentTO: '',
      SelectedGrantors: '',
      SelectedSelGrantors: '',
      CCEmailList: '',
      selfGrantingStatus: 'No',
      selfGrantor: 'Yes'
    };
    this.Limits = [];
  }
  modifyGrantingSatus(option) {

    if (option == "Yes") {
      this.selfTabData.selfGrantingStatus = "Yes";
    } else if (option == "No") {
      this.selfTabData.selfGrantingStatus = "No";
      this.selfTabData.CCEmailList = "";
      this.selfTabData.SelectedSelGrantors = "";
    }
    else {
      this.selfTabData.selfGrantingStatus = "Other";
      this.OthersMailOpt = '';
      this.Others_self = '';
    }
  }
  modifyGranting(option) {
    if (option == "Yes") {
      this.selfTabData.selfGrantor = "Yes";
      this.selfTabData.GrantorID = "";
      this.selfTabData.GrantorSelID = "";
      this.selfTabData.GrantorEmail = "";
    } else if (option == "No") {
      this.selfTabData.selfGrantor = "No";
      this.selfTabData.ManagerID = "";
      this.selfTabData.ManagerName = "";
      this.selfTabData.ManagerEmail = "";
    }
    else {
      this.selfTabData.selfGrantor = "Other";
      this.selfTabData.ManagerID = "";
      this.selfTabData.ManagerName = "";
      this.selfTabData.ManagerEmail = "";

      this.selfTabData.GrantorID = "";
      this.selfTabData.GrantorSelID = "";
      this.selfTabData.GrantorEmail = "";
    }
  }
  /* ******************** Self Tab End ******************* */
  /* ******************** Subordinate Tab Start ***************** */
  GetSubordinateGrantor(event) {
    this.SubOrdinateTabData.EmployeeName = event.target.options[event.target.options.selectedIndex].text;
    this.SubOrdinateTabData.EmployeeEmail = "";
    this.SubOrdinateTabData.EmployeeId = "";
    this.SubOrdinateTabData.CategoryId = "";
    this.SubOrdinateTabData.CatergoryValue = "";
    this.SubOrdinateTabData.ExistingLimit = "";
    this.SubOrdinateTabData.LimitValue = "";
    this.SubOrdinateTabData.CCEmailList = "";
    this.SubOrdinateTabData.GrantorEmail = "";
    this.SubOrdinateTabData.GrantorSelID = "";
    if (this.SubOrdinateTabData.EmployeeSelId != "" && this.SubOrdinateTabData.EmployeeSelId != null && this.SubOrdinateTabData.EmployeeSelId != undefined) {
      let EmployeeSelID = this.SubOrdinateTabData.EmployeeSelId;
      EmployeeSelID = EmployeeSelID.split(',');
      this.SubOrdinateTabData.EmployeeId = EmployeeSelID[0];
      this.SubOrdinateTabData.EmployeeEmail = EmployeeSelID[1];
      if (this.SubordinateGrantorsOpt.length <= 0) {
        this.spinner.show();
        var ObjData = { Id: this.SubOrdinateTabData.EmployeeId }
        this._appServ.getGrantorsData(ObjData).subscribe((result) => {
          this.spinner.hide();
          let resultSet = result;
          this.GrantorSubordinateIdEmail(resultSet["SearchID"]);
        },
          (error) => {
            if (error.status == 401) {
              this._appServ.getSAMLServiceAuthorize();
              //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
            }

            else
              this._commonService.ToastMsg("Error", "Error!", error.message);
            this.spinner.hide();
          });
      }
    }
  }
  filterSubordinateAnyone(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.mulitpleSubordinateGrantorsOpt = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  GrantorSubordinateIdEmail(GrantorOPT) {
    for (var i = 0; i < GrantorOPT.length; i++) {
      GrantorOPT[i].IdEmail = GrantorOPT[i].Id + ',' + GrantorOPT[i].Email;
    }
    this.SubordinateGrantorsOpt = GrantorOPT;
  }

  SubordinateGrantorEmail(event) {
    var EmailId = event;
    EmailId = EmailId.split(",");
    this.SubOrdinateTabData.GrantorEmail = EmailId[1];
  }
  OnSelectedSubordinateAnyone(event) {
    this.SubordinateEmployeeEmail(this.SubOrdinateTabData.SelectedSelGrantors);
  }
  OnUnSelectedSubordinateAnyone(event) {
    this.SubordinateEmployeeEmail(this.SubOrdinateTabData.SelectedSelGrantors);
  }
  SubordinateEmployeeEmail(event) {
    this.SubOrdinateTabData.CCEmailList = "";
    this.SubOrdinateTabData.SelectedGrantors = "";

    for (var i = 0; i < event.length; i++) {
      let GrantorID = event[i].Details;
      GrantorID = GrantorID.split(', ');
      if (i == 0) {
        this.SubOrdinateTabData.CCEmailList = GrantorID[1];
        this.SubOrdinateTabData.SelectedGrantors = GrantorID[0];
      }
      else {
        this.SubOrdinateTabData.CCEmailList += ";" + GrantorID[1];
        this.SubOrdinateTabData.SelectedGrantors += "," + GrantorID[0];
      }
    }
  }
  GetLimitSubordinate(event) {
    this.SubOrdinateTabData.CatergoryValue = event.target.options[event.target.options.selectedIndex].text;

    if (!this.SubOrdinateTabData.CategoryId) {
      this.limit = '';
      this.SubOrdinateTabData.CatergoryValue = "";
      this.SubOrdinateTabData.ExistingLimit = "";
      this.SubOrdinateTabData.LimitValue = "";
      this.Limits = [];
      this.SubOrdinateTabData.LimitID = "";
      return false;
    }
    this.spinner.show();
    var EmployeeId = "-1";
    if (this.SubOrdinateTabData.EmployeeSelId != "") {
      EmployeeId = this.SubOrdinateTabData.EmployeeId;
    }
    var ObjData = { CategoryId: this.SubOrdinateTabData.CategoryId, Id: EmployeeId };
    this._appServ.getSelfLimit(ObjData).subscribe((result) => {
      this.LimitsOpt = result[0];
      if (this.LimitsOpt.length > 0) {
        this.Limits = result[0];
      }
      else {
        this.Limits = [];
      }
      if (result[1] != "") {
        var LimitId = result[1][0];
        this.SubOrdinateTabData.LimitID = LimitId.CostLimitId;
        this.SubOrdinateTabData.LimitValue = this.Limits.filter(function(element) { return element.CostLimitId === result[1][0].CostLimitId;})[0].CostLimitValue;
      }
      else
        this.SubOrdinateTabData.LimitID = "";
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
      var ObjCompData = { GroupId: this.SubOrdinateTabData.CategoryId, Id: EmployeeId };
      this._appServ.getCompany(ObjCompData).subscribe((result) => {
        debugger;
        this.CompanyOpt = result[0];
        if (this.CompanyOpt.length > 0) {
          this.CompanyCodes = result[0];
        }
        else {
          this.CompanyCodes = [];
        }
        if (result[1] != "") {
          this.SubOrdinateTabData.CompanyCodes = result[1];
        }
        else
        this.SubOrdinateTabData.CompanyCodes = [];
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
        });
  }
  onSubordinateLimitChange(event) {
    this.SubOrdinateTabData.LimitValue = event.target.options[event.target.options.selectedIndex].text;
  }
  SubordinateIdEmail(SubordinateOPT) {
    for (var i = 0; i < SubordinateOPT.length; i++) {
      SubordinateOPT[i].IdEmail = SubordinateOPT[i].EmployeeId + ',' + SubordinateOPT[i].Email;
    }
    this.SubOrdinateOpt = SubordinateOPT;
  }

  getSubOrdinateTab() {
    if (this.Categories == undefined || this.Categories == '' || this.Categories == null) {
      this.spinner.show();
      this._appServ.getCategories().subscribe((result) => {
        this.Categories = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
    if (this.SubOrdinateOpt == "" || this.SubOrdinateOpt == null || this.SubOrdinateOpt == undefined) {
      var ObjEmpId = { ID: this.loggeduserObj.ParamEmployeeId }
      this._appServ.getSubordinates(ObjEmpId).subscribe((result) => {
        this.SubordinateIdEmail(result);
        //this.SubOrdinateOpt = result;
        this.spinner.hide();
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  modifyGrantingSatusSubordinate(option) {
    if (option == "Yes") {
      this.SubOrdinateTabData.subordinateGrantingStatus = "Yes";
    } else {
      this.SubOrdinateTabData.subordinateGrantingStatus = "No";
      this.SubOrdinateTabData.CCEmailList = "";
      this.SubOrdinateTabData.SelectedSelGrantors = "";
    }
  }
  modifyGrantingSubordinate(option) {
    if (option == "Yes") {
      this.SubOrdinateTabData.subordinateGrantor = "Yes";
      this.SubOrdinateTabData.GrantorID = "";
      this.SubOrdinateTabData.GrantorSelID = "";
      this.SubOrdinateTabData.GrantorEmail = "";
    } else {
      this.SubOrdinateTabData.subordinateGrantor = "No";
      this.SubOrdinateTabData.ManagerID = "";
      this.SubOrdinateTabData.ManagerName = "";
      this.SubOrdinateTabData.ManagerEmail = "";
    }
  }
  SubmitSubordinate() {
    debugger;
    this.SubOrdinateTabData.CreatedBy = this.loggeduserObj.ParamName;
    this.SubOrdinateTabData.RequestType = "Subordinate";
    if (this.SubOrdinateTabData.EmployeeSelId == "" || this.SubOrdinateTabData.EmployeeSelId == null || this.SubOrdinateTabData.CategoryId == undefined) {
      document.getElementById("selSubordinate").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.SubOrdinateTabData.CategoryId == "" || this.SubOrdinateTabData.CategoryId == null || this.SubOrdinateTabData.CategoryId == undefined) {
      document.getElementById("subordinateCategory").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.SubOrdinateTabData.LimitValue == "" || this.SubOrdinateTabData.LimitValue == null || this.SubOrdinateTabData.LimitValue == undefined) {
      document.getElementById("subordinateLimit").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if(this.SubOrdinateTabData.CategoryId != "5"){
      if (this.SubOrdinateTabData.CompanyCodes == "" || this.SubOrdinateTabData.CompanyCodes == null || this.SubOrdinateTabData.CompanyCodes == undefined) {
        document.getElementById("subordinateCompany").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SubOrdinateTabData.selfGrantor == "No") {
      if (this.SubOrdinateTabData.GrantorSelID == "" || this.SubOrdinateTabData.GrantorSelID == null || this.SubOrdinateTabData.GrantorSelID == undefined) {
        document.getElementById("selSubordinateGrantorName").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      if (this.SubOrdinateTabData.GrantorEmail == "" || this.SubOrdinateTabData.GrantorEmail == null || this.SubOrdinateTabData.GrantorEmail == undefined) {
        document.getElementById("selSubordinateGrantorEmail").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SubOrdinateTabData.subordinateGrantingStatus == "Yes") {
      if (this.SubOrdinateTabData.SelectedGrantors == "" || this.SubOrdinateTabData.SelectedGrantors == null || this.SubOrdinateTabData.SelectedGrantors == undefined) {
        this.autoCompleteSubordinateObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      if (this.SubOrdinateTabData.CCEmailList == "" || this.SubOrdinateTabData.CCEmailList == null || this.SubOrdinateTabData.CCEmailList == undefined) {
        document.getElementById("subordinateMailCC").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SubOrdinateTabData.subordinateGrantor == "No") {
      if (this.SubOrdinateTabData.GrantorSelID != "") {
        let GrantorID = this.SubOrdinateTabData.GrantorSelID;
        GrantorID = GrantorID.split(',');
        this.SubOrdinateTabData.GrantorID = GrantorID[0];
        this.SubOrdinateTabData.SentTO = this.SubOrdinateTabData.GrantorEmail;
      }
      else {
        this.SubOrdinateTabData.GrantorID = "";
      }
    }
    else {
      this.SubOrdinateTabData.ManagerID = this.loggeduserObj.ParamEmployeeId;
      this.SubOrdinateTabData.ManagerName = this.loggeduserObj.ParamFirstName + " " + this.loggeduserObj.ParamLastName;
      this.SubOrdinateTabData.ManagerEmail = this.loggeduserObj.ParamMail;
      this.SubOrdinateTabData.SentTO = this.SubOrdinateTabData.ManagerEmail;
    }
    this.spinner.show();
    this._appServ.RequestModification(this.SubOrdinateTabData).subscribe((result) => {
      this.spinner.hide();
      if (result == "Success") {

        this.NewIpcCategory.CategoryId = this.SubOrdinateTabData.CategoryId;
        this.NewIpcCategory.EmployeeId = this.SubOrdinateTabData.EmployeeId;
        this.NewIpcCategory.CreatedBy = this.loggeduserObj.ParamEmployeeId;
        this.NewIpcCategory.RequestedCostLimit = this.SubOrdinateTabData.LimitValue
        this.NewIpcCategory.RequestedIPCCostLimitId = this.SubOrdinateTabData.LimitID;

        this._appServ.SaveIPCCostLimitChangeRequests(this.NewIpcCategory).subscribe((result) => {
        if (result == "Saved"){

          this._commonService.ToastMsg("Success", "Success!", "Request Modification has been sent successfully for Subordinate");
          this.ClearSubordinateValues();
        }
      });

        
      }
      else {
        this.ToastrWarning("Warning!", result);
      }
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  ClearSubordinateValues() {
    this.SubOrdinateTabData = {
      EmployeeName: '',
      EmployeeEmail: '',
      RequestType: '',
      EmployeeId: '',
      EmployeeSelId: '',
      CategoryId: '',
      CatergoryValue: '',
      GrantorID: '',
      GrantorSelID: '',
      GrantorName: '',
      GrantorEmail: '',
      ManagerID: '',
      ManagerName: '',
      ManagerEmail: '',
      ExistingLimit: '',
      LimitValue: '',
      LimitID: '',
      SentTO: '',
      SelectedGrantors: '',
      SelectedSelGrantors: '',
      CCEmailList: '',
      subordinateGrantingStatus: 'No',
      subordinateGrantor: 'Yes'
    };
    this.Limits = [];
    this.SubordinateGrantorsOpt = "";
    this.mulitpleSubordinateGrantorsOpt = "";
  }

  /* ******************** Subordinate Tab End ******************* */
  /* ******************** SomeoneElse Tab Start ***************** */
  getSomeoneElseTab() {
    if (this.Categories == undefined || this.Categories == '' || this.Categories == null) {
      this.spinner.show();
      this._appServ.getCategories().subscribe((result) => {
        this.Categories = result;
        this.spinner.hide();
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  GetLimitSomeoneElse(event) {
    this.SomeoneElseTabData.CatergoryValue = event.target.options[event.target.options.selectedIndex].text;

    if (!this.SomeoneElseTabData.CategoryId) {
      this.limit = '';
      this.SomeoneElseTabData.CatergoryValue = "";
      this.SomeoneElseTabData.ExistingLimit = "";
      this.SomeoneElseTabData.LimitValue = "";
      this.Limits = [];
      this.SomeoneElseTabData.LimitID = "";
      return false;
    }
    this.spinner.show();

    var EmployeeId = "-1";
    if (this.SomeoneElseTabData.EmployeeId != "") {
      EmployeeId = this.SomeoneElseTabData.EmployeeId;
    }
    var ObjData = { CategoryId: this.SomeoneElseTabData.CategoryId, Id: EmployeeId };
    this._appServ.getSelfLimit(ObjData).subscribe((result) => {
      this.LimitsOpt = result[0];
      if (this.LimitsOpt.length > 0) {
        this.Limits = result[0];
      }
      else {
        this.Limits = [];
      }
      if (result[1] != "") {
        var LimitId = result[1][0];
        this.SomeoneElseTabData.LimitID = LimitId.CostLimitId;
        this.SomeoneElseTabData.LimitValue = this.Limits.filter(function(element) { return element.CostLimitId === result[1][0].CostLimitId;})[0].CostLimitValue;
      }
      else {
        this.SomeoneElseTabData.LimitID = "";
      }
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
      var ObjCompData = { GroupId: this.SomeoneElseTabData.CategoryId, Id: EmployeeId };
      this._appServ.getCompany(ObjCompData).subscribe((result) => {
        debugger;
        this.CompanyOpt = result[0];
        if (this.CompanyOpt.length > 0) {
          this.CompanyCodes = result[0];
        }
        else {
          this.CompanyCodes = [];
        }
        if (result[1] != "") {
          this.SomeoneElseTabData.CompanyCodes = result[1];
        }
        else
        this.SomeoneElseTabData.CompanyCodes = [];
        this.spinner.hide();
      },
        (error) => {
          this.spinner.hide();
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
        });
  }
  onSomeoneelseLimitChange(event) {
    this.SomeoneElseTabData.LimitValue = event.target.options[event.target.options.selectedIndex].text;
  }
  modifyGrantingSatusSomeoneElse(option) {
    if (option == "Yes") {
      this.SomeoneElseTabData.subordinateGrantingStatus = "Yes";
    } else {
      this.SomeoneElseTabData.subordinateGrantingStatus = "No";
      this.SomeoneElseTabData.CCEmailList = "";
      this.SomeoneElseTabData.SelectedSelGrantors = "";
    }
  }
  modifyGrantingSomeoneElse(option) {
    if (option == "Yes") {
      this.SomeoneElseTabData.subordinateGrantor = "Yes";
      this.SomeoneElseTabData.GrantorID = "";
      this.SomeoneElseTabData.GrantorSelID = "";
      this.SomeoneElseTabData.GrantorEmail = "";
    } else {
      this.SomeoneElseTabData.subordinateGrantor = "No";
      this.SomeoneElseTabData.ManagerID = "";
      this.SomeoneElseTabData.ManagerName = "";
      this.SomeoneElseTabData.ManagerEmail = "";
    }
  }
  OnSelectedSomeoneName() {
    this.SomeoneElseTabData.CatergoryValue = "";
    this.SomeoneElseTabData.ExistingLimit = "";
    this.SomeoneElseTabData.LimitValue = "";
    this.Limits = [];
    this.SomeoneElseTabData.LimitID = "";

    this.SomeoneElseTabData.EmployeeName = this.SomeoneElseTabData.EmployeeSelId["fullName"];

    let EmployeeSelID = this.SomeoneElseTabData.EmployeeSelId["Details"];
    EmployeeSelID = EmployeeSelID.split(',');
    this.SomeoneElseTabData.EmployeeEmail = EmployeeSelID[1];
    this.SomeoneElseTabData.EmployeeId = EmployeeSelID[0];
    var objData = { ID: this.SomeoneElseTabData.EmployeeId };
    this.spinner.show();
    this._appServ.getManagers(objData).subscribe((result) => {
      this.SomeoneElseManagerID = result[0][0]["EmployeeId"];
      this.SomeoneElseManagerName = result[0][0]["FirstName"] + " " + result[0][0]["LastName"];
      this.SomeoneElseManagerEmail = result[0][0]["Email"];
      this.spinner.hide();
      this.GrantorSomeoneElseIdEmail(result[1]["SearchID"]);
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  GrantorSomeoneElseIdEmail(GrantorOPT) {
    for (var i = 0; i < GrantorOPT.length; i++) {
      GrantorOPT[i].IdEmail = GrantorOPT[i].Id + ',' + GrantorOPT[i].Email;
    }
    this.SomeoneElseGrantorsOpt = GrantorOPT;
  }
  OnUnSelectedSomeoneName() {

  }
  ClearSomeoneName() {
    this.SomeoneElseManagerID = "";
    this.SomeoneElseManagerName = "";
    this.SomeoneElseManagerEmail = "";
  }
  filterSomeoneName(value) {
    if (value.query.length > 3) {
      this.SomeoneElseTabData.CatergoryValue = "";
      this.SomeoneElseTabData.ExistingLimit = "";
      this.SomeoneElseTabData.LimitValue = "";
      this.Limits = [];
      this.SomeoneElseTabData.LimitID = "";
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.SomeoneElseOpt = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  SomeoneElseGrantorEmail(event) {
    var EmailId = event;
    EmailId = EmailId.split(",");
    this.SomeoneElseTabData.GrantorEmail = EmailId[1];
  }
  SomeoneElseEmployeeEmail(event) {
    this.SomeoneElseTabData.CCEmailList = "";
    this.SomeoneElseTabData.SelectedGrantors = "";

    for (var i = 0; i < event.length; i++) {
      let GrantorID = event[i].Details;
      GrantorID = GrantorID.split(', ');
      if (i == 0) {
        this.SomeoneElseTabData.CCEmailList = GrantorID[1];
        this.SomeoneElseTabData.SelectedGrantors = GrantorID[0];
      }
      else {
        this.SomeoneElseTabData.CCEmailList += ";" + GrantorID[1];
        this.SomeoneElseTabData.SelectedGrantors += "," + GrantorID[0];
      }
    }
  }
  OnSelectedSomeoneElseAnyone(event) {
    this.SomeoneElseEmployeeEmail(this.SomeoneElseTabData.SelectedSelGrantors);
  }
  OnUnSelectedSomeoneElseAnyone(event) {
    this.SomeoneElseEmployeeEmail(this.SomeoneElseTabData.SelectedSelGrantors);
  }
  filterSomeoneElseAnyone(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.mulitpleSomeoneElseGrantorsOpt = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  SubmitSomeoneElseTab() {
    this.SomeoneElseTabData.CreatedBy = this.loggeduserObj.ParamName;
    this.SomeoneElseTabData.RequestType = "Someone Else";
    if (this.SomeoneElseTabData.EmployeeSelId == "" || this.SomeoneElseTabData.EmployeeSelId == null || this.SomeoneElseTabData.CategoryId == undefined) {
      this.autoCompleteSomeoneNameObject.focusInput();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    else {
      var IsEmployeeSelected = !!this.SomeoneElseTabData.EmployeeSelId.Details;
      if (!IsEmployeeSelected) {
        this.autoCompleteSomeoneNameObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SomeoneElseTabData.CategoryId == "" || this.SomeoneElseTabData.CategoryId == null || this.SomeoneElseTabData.CategoryId == undefined) {
      document.getElementById("someoneElseCategory").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.SomeoneElseTabData.LimitValue == "" || this.SomeoneElseTabData.LimitValue == null || this.SomeoneElseTabData.LimitValue == undefined) {
      document.getElementById("someoneElselimit").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if(this.SomeoneElseTabData.CategoryId != "5"){
      if (this.SomeoneElseTabData.CompanyCodes == "" || this.SomeoneElseTabData.CompanyCodes == null || this.SomeoneElseTabData.CompanyCodes == undefined) {
        document.getElementById("someoneCompany").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SomeoneElseTabData.selfGrantor == "No") {
      if (this.SomeoneElseTabData.GrantorSelID == "" || this.SomeoneElseTabData.GrantorSelID == null || this.SomeoneElseTabData.GrantorSelID == undefined) {
        document.getElementById("selSomeoneElseGrantorName").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      if (this.SomeoneElseTabData.GrantorEmail == "" || this.SomeoneElseTabData.GrantorEmail == null || this.SomeoneElseTabData.GrantorEmail == undefined) {
        document.getElementById("selSubordinateGrantorEmail").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SomeoneElseTabData.subordinateGrantingStatus == "Yes") {
      if (this.SomeoneElseTabData.SelectedGrantors == "" || this.SomeoneElseTabData.SelectedGrantors == null || this.SomeoneElseTabData.SelectedGrantors == undefined) {
        this.autoCompleteSomeoneElseObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      if (this.SomeoneElseTabData.CCEmailList == "" || this.SomeoneElseTabData.CCEmailList == null || this.SomeoneElseTabData.CCEmailList == undefined) {
        document.getElementById("someoneElseMailCC").focus();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SomeoneElseTabData.subordinateGrantor == "No") {
      if (this.SomeoneElseTabData.GrantorSelID != "") {
        let GrantorID = this.SomeoneElseTabData.GrantorSelID;
        GrantorID = GrantorID.split(',');
        this.SomeoneElseTabData.GrantorID = GrantorID[0];
        this.SomeoneElseTabData.SentTO = this.SomeoneElseTabData.GrantorEmail;
      }
      else {
        this.SomeoneElseTabData.GrantorID = "";
      }
    }
    else {
      this.SomeoneElseTabData.ManagerID = this.SomeoneElseManagerID;
      this.SomeoneElseTabData.ManagerName = this.SomeoneElseManagerName;
      this.SomeoneElseTabData.ManagerEmail = this.SomeoneElseManagerEmail
      this.SomeoneElseTabData.SentTO = this.SomeoneElseManagerEmail;
    }
    this.spinner.show();
    this._appServ.RequestModification(this.SomeoneElseTabData).subscribe((result) => {
      this.spinner.hide();
      if (result == "Success") {

        this.NewIpcCategory.CategoryId = this.SomeoneElseTabData.CategoryId;
        this.NewIpcCategory.EmployeeId = this.SomeoneElseTabData.EmployeeId;
        this.NewIpcCategory.CreatedBy = this.loggeduserObj.ParamEmployeeId;
        this.NewIpcCategory.RequestedCostLimit = this.SomeoneElseTabData.LimitValue
        this.NewIpcCategory.RequestedIPCCostLimitId = this.SomeoneElseTabData.LimitID;

        this._appServ.SaveIPCCostLimitChangeRequests(this.NewIpcCategory).subscribe((result) => {
        if (result == "Saved"){

          this._commonService.ToastMsg("Success", "Success!", "Request Modification has been sent successfully for Someone Else");
        this.ClearSomeoneElseValues();

        }
      });

      }
      else {
        this.ToastrWarning("Warning!", result);
      }
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  ClearSomeoneElseValues() {
    this.SomeoneElseGrantorsOpt = [];
    this.SomeoneElseOpt = [];
    this.SomeoneElseManagerID = '';
    this.SomeoneElseManagerName = '';
    this.SomeoneElseManagerEmail = '';
    this.SomeoneElseTabData = {
      EmployeeName: '',
      EmployeeEmail: '',
      EmployeeId: '',
      EmployeeSelId: '',
      RequestType: '',
      CategoryId: '',
      CatergoryValue: '',
      GrantorID: '',
      GrantorSelID: '',
      GrantorName: '',
      GrantorEmail: '',
      ManagerID: '',
      ManagerName: '',
      ManagerEmail: '',
      ExistingLimit: '',
      LimitValue: '',
      LimitID: '',
      SentTO: '',
      SelectedGrantors: '',
      SelectedSelGrantors: '',
      CCEmailList: '',
      SomeoneElseGrantingStatus: 'No',
      SomeoneElseGrantor: 'Yes'
    };
    this.Limits = [];
  }
  /* ******************** SomeoneElse Tab End ***************** */
  /* ******************** Surrogate Tab Begin ******************* */
  getSurrogateTab() {
    this.ClearSurrogateValues();
  }
  ClearSurrogateOther() {
    this.SurrogateTabData.surrogateOtherName = "";
    this.SurrogateTabData.SurrogateOtherId = "";
    this.SurrogateTabData.surrogateOtherSelId = "";
    this.SurrogateTabData.surrogateOtherMail = "";
  }
  ClearSurrogateTo() {
    this.SurrogateTabData.surrogateToName = "";
    this.SurrogateTabData.SurrogatetoId = "";
    this.SurrogateTabData.surrogateToSelId = "";
    this.SurrogateTabData.surrogateToMail = "";
  }
  modifySurrogateSatus(option: string) {
    if (option == "Yes") {
      this.surrogateStatus = "Yes";
      this.SurrogateTabData.surrogateOtherName = "";
      this.SurrogateTabData.SurrogateOtherId = "";
      this.SurrogateTabData.surrogateOtherSelId = "";
      this.SurrogateTabData.surrogateOtherMail = "";
    } else {
      this.surrogateStatus = "No";
    }
  }
  SurrogateOtherChange($event) {
    this.SurrogateTabData.surrogateOtherName = $event.target.options[$event.target.options.selectedIndex].text;
  }
  SurrogateToChange($event) {
    this.SurrogateTabData.surrogateToName = $event.target.options[$event.target.options.selectedIndex].text;
  }
  onSelectDateTo(event) {
    let d = new Date(Date.parse(event));
    this.SurrogateTabData.ToDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }
  onSelectDateFrom(event) {
    let d = new Date(Date.parse(event));
    this.SurrogateTabData.FromDate = `${d.getMonth() + 1}/${d.getDate()}/${d.getFullYear()}`;
  }
  SurrogateSubmit() {
    if (this.surrogateStatus == 'No') {
      if (this.SurrogateTabData.surrogateOtherSelId == "" || this.SurrogateTabData.surrogateOtherSelId == null || this.SurrogateTabData.surrogateOtherSelId == undefined) {
        this.autoCompleteSurrogateOtherObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
      else {
        var IsOtherEmployeeSelected = !!this.SurrogateTabData.surrogateOtherSelId.Details;
        if (!IsOtherEmployeeSelected) {
          this.autoCompleteSurrogateOtherObject.focusInput();
          this.ToastrWarning("Field Required!", "Fields are Mandatory");
          return false;
        }
      }
      if (this.SurrogateTabData.surrogateToName == this.SurrogateTabData.surrogateOtherName) {
        this.autoCompleteSurrogateToObject.focusInput();
        this.ToastrWarning("Change Field", "Please change the Surrogate User");
        return false;
      }
    }
    if (this.SurrogateTabData.surrogateToSelId == "" || this.SurrogateTabData.surrogateToSelId == null || this.SurrogateTabData.surrogateToSelId == undefined) {
      this.autoCompleteSurrogateToObject.focusInput();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    else {
      var IsToEmployeeSelected = !!this.SurrogateTabData.surrogateToSelId.Details;
      if (!IsToEmployeeSelected) {
        this.autoCompleteSurrogateToObject.focusInput();
        this.ToastrWarning("Field Required!", "Fields are Mandatory");
        return false;
      }
    }
    if (this.SurrogateTabData.from == undefined || this.SurrogateTabData.from == '') {
      document.getElementById('iconFrom').focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.SurrogateTabData.to == undefined || this.SurrogateTabData.to == '') {
      document.getElementById('iconTo').focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }

    if (this.surrogateStatus == "No") {
      this.SurrogateTabData.SelfSurrogate = false;
    }
    else {
      this.SurrogateTabData.SelfSurrogate = true;
      this.SurrogateTabData.surrogateOtherName = "";
      this.SurrogateTabData.SurrogateOtherId = "";
      this.SurrogateTabData.surrogateOtherSelId = "";
      this.SurrogateTabData.surrogateOtherMail = "";
    }
    this.SurrogateTabData.LoggedInUserId = this.loggeduserObj.ParamEmployeeId;
    this.SurrogateTabData.LoggedInUserEmail = this.loggeduserObj.ParamMail;
    this.SurrogateTabData.LoggedInUser = this.loggeduserObj.ParamFirstName + " " + this.loggeduserObj.ParamLastName;

    this.spinner.show();

    this._appServ.RequestModificationSurrogate(this.SurrogateTabData).subscribe((result) => {
      this.spinner.hide();
      this._commonService.ToastMsg("Success", "Success!", "Surrogate has been informed");
      this.ClearSurrogateValues();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  OnSelectedSurrogateOther() {
    this.SurrogateTabData.surrogateOtherName = this.SurrogateTabData.surrogateOtherSelId["fullName"];

    let EmployeeSelID = this.SurrogateTabData.surrogateOtherSelId["Details"];
    EmployeeSelID = EmployeeSelID.split(',');
    this.SurrogateTabData.surrogateOtherMail = EmployeeSelID[1];
    this.SurrogateTabData.SurrogateOtherId = EmployeeSelID[0];
  }
  OnSelectedSurrogateTo() {
    this.SurrogateTabData.surrogateToName = this.SurrogateTabData.surrogateToSelId["fullName"];

    let EmployeeSelID = this.SurrogateTabData.surrogateToSelId["Details"];
    EmployeeSelID = EmployeeSelID.split(',');
    this.SurrogateTabData.surrogateToMail = EmployeeSelID[1];
    this.SurrogateTabData.SurrogatetoId = EmployeeSelID[0];
  }
  filterSurrogateOther(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.mulitpleSurrogateOpt = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  filterSurrogateTo(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.mulitpleSurrogateToOpt = result;
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
            //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }
  ClearSurrogateValues() {
    this.surrogateStatus = "Yes";
    this.SurrogateTabData = {
      SelfSurrogate: ' ',
      surrogateOtherMail: '',
      surrogateToMail: '',
      from: '',
      to: '',
      surrogateOtherName: '',
      SurrogateOtherId: '',
      surrogateOtherSelId: '',
      surrogateToSelId: '',
      SurrogatetoId: '',
      surrogateToName: '',
      LoggedInUserId: '',
      LoggedInUserEmail: '',
      FromDate: '',
      ToDate: '',
      CCEmailList: ''
    }
  }
  /* ******************** Surrogate Tab End ******************* */
  transform(value: any): any {
    if (value === undefined)
      return undefined;
    return value.replace(/\s/g, "");
  }
  openTab(data) {
    if (data == "Self") {
      this.getSelfTab();
      this.ClearSelfValues();
    }
    else if (data == "Subordinate") {
      this.getSubOrdinateTab();
      this.ClearSubordinateValues();
    }
    else if (data == "Someone Else") {
      this.getSomeoneElseTab();
      this.ClearSomeoneElseValues();
    }
    else if (data == "Surrogate Addition") {
      this.ClearSurrogateValues();
    }
    else if (data == "Surrogate Addition") {
      this.getSurrogateTab();
    }
    this.showSearchResults = false;
    this.showTreeDiv = false;
    this.activeTab = data;
    this.showTablist = true;
    this.selectedCategory = null;
    this.limit = null;
    this.grantingStatus = "";
    this.grantingStatus1 = "";
    this.grantorsFullName = "";
    this.grantorsEmail = "";
  }

  CancelTab(TabName) {
    //SomeoneElse
    if (TabName == 'Self')
      this.ClearSelfValues();
    else if (TabName == 'Subordinate')
      this.ClearSubordinateValues();
    else if (TabName == 'SomeoneElse')
      this.ClearSomeoneElseValues();
    else if (TabName == "Surrogate")
      this.ClearSurrogateValues();
    this.showSearchResults = false;
    this.showTreeDiv = true;
    this.showTablist = false;
  }
  loadNode(event) {

  }

}
