import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest
} from '@angular/common/http';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor() { }
  getContentType() {
    return 'application/json';
  }
  intercept(req: HttpRequest<any>, next: HttpHandler) {

    // // this.res.setHeader('Access-Control-Allow-Origin', '*');

    // // Request methods you wish to allow
    // this.res={Access-Control-Allow-Methods: 'GET, POST, OPTIONS, PUT, PATCH, DELETE'};

    // // Request headers you wish to allow
    // this.res.setHeader('Access-Control-Allow-Headers', 'X-Requested-With,content-type');

    // Set to true if you need the website to include cookies in the requests sent
    // to the API (e.g. in case you use sessions)
    // this.res.setHeader('Access-Control-Allow-Credentials', true);
    const AuthToken = "Bearer " + sessionStorage.getItem("AuthToken");    
    const contentType = this.getContentType();
    const authReq = req.clone({ setHeaders: { 'Access-Control-Allow-Methods': '*', 'Access-Control-Allow-Origin': '*', Authorization: AuthToken, contentType: contentType, 'Content-Type': contentType, 'Cache-Control': 'no-cache' } });
    return next.handle(authReq);
  }
}