import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './interceptor';

import { TableModule } from 'primeng/table';
import { TreeModule } from 'primeng/tree';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { DialogModule } from 'primeng/dialog';
import { ConfirmDialogModule} from 'primeng/confirmdialog';
//import { ChipModule } from 'primeng/chip';


import { AppComponent } from './app.component';
import { HomePageComponent } from './home-page/home-page.component';
import { SearchComponent } from './search/search.component';
import { HeaderComponent } from './header/header.component';
import { ApprovalComponent } from './approval/approval.component';
import { FooterComponent } from './footer/footer.component';
import { LoginComponentManual } from './login-manual/login-manual.component';
import { IPCostLimitManagement } from './ipCostLimit-management/ipCostLimit-management.component';

import { SharedService } from './shared/shared.service';
import { AppServicesService } from './services/app-services.service';
import { ReportsComponent } from './reports/reports.component';
import { LoginComponent } from './login/login.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { CategoryManagementComponent } from './category-management/category-management.component';
import { NumberDirective } from './number-only.directive';
import { IpcModficationRequestComponent } from './ipc-modfication-request/ipc-modfication-request.component';
import { CompanyManagementComponent } from './company-management/company-management.component';


@NgModule({
  declarations: [
    AppComponent,
    HomePageComponent,
    SearchComponent,
    HeaderComponent,
    ApprovalComponent,    
    FooterComponent,
    ReportsComponent,
    LoginComponent,
    UserManagementComponent,
    CategoryManagementComponent ,
    NumberDirective,
    LoginComponentManual,
    IpcModficationRequestComponent,
    IPCostLimitManagement,
    CompanyManagementComponent    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,    
    FormsModule,    
    HttpClientModule,
    NgxSpinnerModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-top-right',
      preventDuplicates: true,
      closeButton: true
    }),
    TableModule,
    TreeModule,
    BrowserAnimationsModule,
    DropdownModule,
    RadioButtonModule,
    CalendarModule,
    MultiSelectModule,
    AutoCompleteModule,
    DialogModule,
    ConfirmDialogModule//,
    //NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [
    SharedService,
    AppServicesService,
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
