import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomePageComponent } from './home-page/home-page.component';
import { ApprovalComponent } from './approval/approval.component';
import { ReportsComponent } from './reports/reports.component';
import { LoginComponent } from './login/login.component';
import { LoginComponentManual } from './login-manual/login-manual.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { CategoryManagementComponent } from './category-management/category-management.component';
import { IpcModficationRequestComponent } from './ipc-modfication-request/ipc-modfication-request.component';
import { IPCostLimitManagement } from './ipCostLimit-management/ipCostLimit-management.component';
import { CompanyManagementComponent } from './company-management/company-management.component';

const routes: Routes = [
  {
    path: '', pathMatch: 'full', component: LoginComponent
  },
  {
    path: 'home', component: HomePageComponent
  },
  {
    path: 'approval', component: ApprovalComponent
  },
  {
    path: 'reports', component: ReportsComponent
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'admin', component: UserManagementComponent
  },
  {
    path: 'category', component: CategoryManagementComponent
  },
  {
    path: 'company', component: CompanyManagementComponent
  },
  {
    path: 'login-manual', component: LoginComponentManual
  },
  {
    path: 'ipc-modification', component: IpcModficationRequestComponent
  },
  {
    path: 'ipcostlimt', component: IPCostLimitManagement
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
