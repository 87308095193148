import { Component,OnInit, ViewEncapsulation} from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { AppServicesService } from '../services/app-services.service';
import { NgxSpinnerService  } from 'ngx-spinner';
import { Router } from '@angular/router';

@Component({
    selector:'app-ipcostlimit-management',
    templateUrl:'./ipCostLimit-management.component.html',
    styleUrls: ['./ipCostLimit-management.component.css'],
    encapsulation: ViewEncapsulation.None
})


export class IPCostLimitManagement implements OnInit{
    id = null;
    costLimitDesc ="";
    costLimitValue="";
    currencyName="";
    currencySymbol="";
    aRole="";
    ipCostLimitData: any;
    userName: any;
    ipCostLimtCols: any;
    editing: boolean = false;
    apiResp: any;
    constructor(private _commonService: SharedService, private _appServ: AppServicesService, private router: Router, private spinner: NgxSpinnerService) { }
    ngOnInit() {
        this.userName = sessionStorage.getItem('ACSuserName');
        if (this.userName == "undefined" || this.userName == undefined || this.userName == null) {
                this.router.navigate(['/login']);
        }

        this.GetIpcostLimits();


        this.ipCostLimtCols = [
            { field: "CostLimitDesc", header: "Cost Limit Desc", width: "100px" },
            { field: "CostLimitValue", header: "Cost Limit Value", width: "100px" },
            { field: "CurrencyName", header: "Currency Name", width: "80px" },
            { field: "CurrencySymbol", header: "Currency Symbol", width: "80px" },
            { field: "ARole", header: "Role", width: "80px" },
            { field: "CreatedBy", header: "Created By", width: "80px" },
          ]
    }

    GetIpcostLimits(){
        this.spinner.show();
        this._appServ.getIPcostLimits().subscribe((result) => {
          this.spinner.hide();
          this.ipCostLimitData = result;
        },
          (error) => {
            if (error.status == 401){
              this._appServ.getSAMLServiceAuthorize();
            }          
            else
              this._commonService.ToastMsg("Error", "Error!", error.message);
              this.spinner.hide();
          });
    }

    onDeleteIpCostLimit(rowData) {
        
        let IpcostInfo = {
          IPCCostLimitId: rowData.IPCCostLimitId,
          CostLimitDesc: '',
          CostLimitValue: '',
          CurrencyName: '',
          CurrencySymbol: '',
          ARole: '',
          CreatedBy: this.userName,
          ActionId: 2          
        }
        this.spinner.show();
        this._appServ.saveIPCostLimit(IpcostInfo).subscribe((result) => {
          this.spinner.hide();
          this.GetIpcostLimits();
          this._commonService.ToastMsg("Success", "Success!", result);
        },
          (error) => {
            this._commonService.ToastMsg("Error", "Error!", error);
            this.spinner.hide();
          });
    }

    onCancel() {
        this.editing = false;
        this.costLimitDesc = "";
        this.id = "";
        this.costLimitValue = "";
        this.currencyName = "";
        this.currencySymbol = "";
        this.aRole="";
    }

    onRowEditInit(rowData) {
        this.editing = true;
        this.costLimitDesc = rowData.CostLimitDesc;
        this.id = rowData.IPCCostLimitId;
        this.costLimitValue = rowData.CostLimitValue;
        this.currencyName = rowData.CurrencyName;
        this.currencySymbol = rowData.CurrencySymbol;
        this.aRole= rowData.ARole;
    }
    // validateCostLimitValue(data :any){
    //     var regexp = /^[0-9]*(\.[0-9]{0,2})?$/;
    //     return  regexp.test(data)
    // }
    saveIpCostLimitValue() {
        if (this.transform(this.costLimitDesc) == '' || this.transform(this.costLimitDesc) == undefined) {
          document.getElementById("costLimitDesc").focus();
          this.ToastrWarning("Field Required!", "Fields are Mandatory");
          return false;
        }
        if (this.transform(this.costLimitValue) == '' || this.transform(this.costLimitValue) == undefined) {
          document.getElementById("costLimitValue").focus();
          this.ToastrWarning("Field Required!", "Fields are Mandatory");
          return false;
        }
        // else{
        //     if (!this.validateCostLimitValue(this.costLimitValue)) {
        //         document.getElementById("costLimitValue").focus();
        //         this.ToastrWarning("Cost Limit Value InValid!", "Enter valid Cost Limit Value");
        //         return false;
        //       }
        // }
        if (this.transform(this.currencyName) == '' || this.transform(this.currencyName) == undefined) {
          document.getElementById("currencyName").focus();
          this.ToastrWarning("Field Required!", "Fields are Mandatory");
          return false;
        }

        if (this.transform(this.currencySymbol) == '' || this.transform(this.currencySymbol) == undefined) {
            document.getElementById("currencySymbol").focus();
            this.ToastrWarning("Field Required!", "Fields are Mandatory");
            return false;
        }

        if (this.transform(this.aRole) == '' || this.transform(this.aRole) == undefined) {
            document.getElementById("aRole").focus();
            this.ToastrWarning("Field Required!", "Fields are Mandatory");
            return false;
        }
       
        this.spinner.show();

        let IpcostInfo = {
            IPCCostLimitId: this.id,
            CostLimitDesc: this.costLimitDesc,
            CostLimitValue: this.costLimitValue,
            CurrencyName: this.currencyName,
            CurrencySymbol: this.currencySymbol,
            ARole: this.aRole,
            CreatedBy: this.userName,
            ActionId: 1         
          }
        this._appServ.saveIPCostLimit(IpcostInfo).subscribe((result) => {
          this.spinner.hide();
          this.GetIpcostLimits();
          this._commonService.ToastMsg("Success", "Success!", result);
        },
          (error) => {
            this._commonService.ToastMsg("Error", "Error!", error);
            this.spinner.hide();
          });
    
        this.GetIpcostLimits();
        this.onCancel();
    }

    transform(value: any): any {
        if (value === undefined)
          return undefined;
        return value.replace(/\s/g, "");
      }
      ToastrWarning(Title, Message) {
        this._commonService.ToastMsg("Warning", Title, Message);
      }
}