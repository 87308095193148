import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppServicesService } from '../services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { SharedService } from '../shared/shared.service';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class ReportsComponent implements OnInit {

  CostCenterOptions = [];
  organisations = [];
  dupOrganisations = [];
  costCenterOpts = [];
  organisationsOpts = [];
  businessOptions = [];
  employeeOptions: any;
  dupEmployeeOptions = [];
  dupBusinessOptions = [];
  employeeNames: any;
  selectedEmployees = [];
  subData = [];
  currencyData = [];
  reportsData = []
  apiResp: any;
  orgReportsData: any;
  frozenCols: any;
  searchKeyword = "";
  selectedOrgUnit: any;
  selectedCostCenter: any;
  selectedEmployeeIds: any;
  eIds = [];
  cIds = [];
  oIds = [];
  loadReportsData = {
    PageNumber: 1,
    EmployeeIDs: [],
    organisationIDs: [],
    CostCenterIDs: [],
    No_Of_Records: 10,
    Keyword: ""
  };
  apiRequest: any;
  startRow: number;
  noOfRows: number;
  totalRecords: number;
  datasource: any;

  excelData = [];

  categoriesData = [];
  userName: any;
  isReport: boolean = true;
  first: number = 0;
  items = [
    { id: 1, name: 'Python' },
    { id: 2, name: 'Node Js' },
    { id: 3, name: 'Java' },
    { id: 4, name: 'PHP', disabled: true },
    { id: 5, name: 'Django' },
    { id: 6, name: 'Angular' },
    { id: 7, name: 'Vue' },
    { id: 8, name: 'ReactJs' },
  ];
  selected = [
    { id: 2, name: 'Node Js' },
    { id: 8, name: 'ReactJs' }
  ];

  constructor(private _appServ: AppServicesService, private router: Router, private spinner: NgxSpinnerService, private _commonService: SharedService) { }

  ngOnInit() {
    this.userName = sessionStorage.getItem('ACSuserName');
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null) {
      this.router.navigate(['/login']);
    }
    this._appServ.getReportFiltersData().subscribe(response => {
      this.apiResp = response;
      this.costCenterOpts = this.apiResp.Table;
      this.organisationsOpts = this.apiResp.Table1;
      this.costCenterOpts.forEach(cc => {
        if (cc.CostCenterName != null) {
          let ccObj = {
            value: cc.CostCenterId,
            label: cc.CostCenterName
          };
          this.businessOptions.push(ccObj);
        }
      });
      this.organisationsOpts.forEach(org => {
        if (org.OrganizationName != null) {
          let orgObj = {
            value: org.OrganizationId,
            label: org.OrganizationName
          }
          this.organisations.push(orgObj);
        }
      });
      this.dupOrganisations = this.organisations;
      this.dupBusinessOptions = this.businessOptions;
    })
    this.frozenCols = [
      { field: 'org', header: 'Org' },
      { field: 'businessUnit', header: 'Business Unit' },
      { field: 'name', header: 'Name' },
      { field: 'matrix', header: 'Matrix' }
    ];
  }


  filterOrganisation(event) {
    let filtered: any[] = [];
    let query = event.query;
    this.organisations = this.dupOrganisations;
    for (let i = 0; i < this.organisations.length; i++) {
      let orgUnit = this.organisations[i];
      if (orgUnit.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(orgUnit);
      }
    }
    this.organisations = filtered;

  }

  filterCostCenter(event) {
    let filtered: any[] = [];
    let query = event.query;
    this.businessOptions = this.dupBusinessOptions;
    for (let i = 0; i < this.businessOptions.length; i++) {
      let costCenter = this.businessOptions[i];
      if (costCenter.label.toLowerCase().indexOf(query.toLowerCase()) == 0) {
        filtered.push(costCenter);
      }
    }
    this.businessOptions = filtered;
  }
  searchReports() {
    let data = {
      first: 0,
      rows: 10
    };
    this.first = 0;
    this.startRow = 0;
    this.totalRecords = 0;
    this.loadReportsData = {
      PageNumber: 1,
      EmployeeIDs: [],
      organisationIDs: [],
      CostCenterIDs: [],
      No_Of_Records: 10,
      Keyword: ""
    };
    this.getReportsData(data);
  }
  filterEmployeeId(value) {
    if (value.query.length > 3) {
      var ObjData = { Name: value.query };
      this._appServ.getFilterEmployee(ObjData).subscribe((result) => {
        this.employeeOptions = result;
      },
        (error) => {
          //this._commonService.ToastMsg("Error", "Error!", error);
          this.spinner.hide();
        });
    }
  }
  loadLazyData(event) {
    setTimeout(() => {
      this.startRow = this.totalRecords != 0 ? event.first + 1 : 0;
      this.first = this.startRow - 1;
      this.noOfRows = this._appServ.getTotalRowsPerPage(event, this.totalRecords);
      if (event.first == 0 && this.reportsData.length == 0) {
      }
      else {
        this.spinner.show();
        this.getReportsData(event);
      }
    }, 200);
  }

  getReportsData(event) {
    this.spinner.show();
    this.spinner.show();
    this.orgReportsData = [];
    this.reportsData = [];
    this._appServ.getReports(this.setFormData(event)).subscribe((result) => {
      this.apiResp = result;
      this.excelData = [];
      this.orgReportsData = JSON.parse(JSON.stringify(this.apiResp.Table));
      this.totalRecords = this.apiResp.Table1[0].Total_no_of_records;
      this.orgReportsData.forEach(data => {
        let subObj = {
          type: data.GourpName,
          categoryCount: 0
        }
        if (this.subData.length > 0) {
          if (this.subData.filter(s => s.type === data.GourpName).length == 0) {
            this.subData.push(subObj);
          }
        } else {
          this.subData.push(subObj);
        }
        if (this.categoriesData.indexOf(data.CategoryName) === -1) {
          this.categoriesData.push(data.CategoryName);
        }
        if (this.currencyData.indexOf(data.CurrencyName) === -1) {
          this.currencyData.push(data.CurrencyName);
        }

      });

      let tempRepObj = [];
      this.orgReportsData.forEach(data => {
        let rObj = {
          Organization: data.OrganizationName,
          CostCenter: data.CostCenterName,
          Name: data.FirstName + ' ' + data.LastName,
          employeeId: data.EmployeeId,
          PersonalNumber: data.PersonalNumber
        }
        if (tempRepObj.length > 0) {
          let dupCount = tempRepObj.filter(t => t.employeeId === data.EmployeeId);
          if (dupCount.length === 0) {
            tempRepObj.push(rObj);
            this.excelData.push(rObj);
          }
        } else {
          tempRepObj.push(rObj);
          this.excelData.push(rObj);
        }
      });
      tempRepObj.forEach((repObj) => {
        repObj.subData = [];
        let filtObj = this.orgReportsData.filter(org => org.EmployeeId == repObj.employeeId);
        for (let i = 0; i < this.subData.length; i++) {
          let categories = [];
          let type = this.subData[i].type;
          let foundSubData = filtObj.filter(f => f.GourpName == this.subData[i].type);
          foundSubData.forEach((sub, index) => {
            // for(let x =0; x < this.currencyData.length;  x++) {
            //   debugger;
            //   if(sub.CurrencyName == this.currencyData[x].CurrencyName) {
            // if (sub.costlimitDesc == null)
            //   sub.costlimitDesc = 'Unlimited';
            let catObj = {
              catName: sub.CategoryName,
              catValue: sub.CostLimitDESC
            };
            categories.push(catObj);
            this.excelData.forEach(d => {
              if (d.employeeId == repObj.employeeId) {
                let sign = sub.CategoryName;
                // if(sub.CurrencyName == repObj.CategoryName) {
                //   sign = sub.CategoryName;
                d[`${type} - ${sign}`] = sub.CostLimitDESC;
                //}
              }
            })
            this.subData.forEach(s => {
              if (s.type == type) {
                s.categoryCount = index + 1;
              }
            })
            //   }
            // }
          });
          let subDataObj = {};
          if (categories.length > 0) {
            subDataObj = {
              type: type,
              categories: categories
            }
          } else {

          }
          repObj.subData.push(subDataObj);
        }
      });
      this.reportsData = JSON.parse(JSON.stringify(tempRepObj));
      if (this.reportsData.length > 0)
        this.isReport = false;
      else
        this.isReport = true;
      this.eIds = [];
      this.cIds = [];
      this.oIds = [];
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401){
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }          
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  searchParameterAdd() {
    this.eIds = [];
    this.cIds = [];
    this.oIds = [];
    if (this.selectedEmployeeIds != undefined) {
      if (this.selectedEmployeeIds.length > 0) {
        let EmployeeID = this.selectedEmployeeIds;
        EmployeeID = EmployeeID.split(',');
        for (var i = 0; i < EmployeeID.length; i++) {
          this.eIds.push(EmployeeID[i]);
        }
      }
    }
    if (this.selectedOrgUnit != undefined) {
      if (this.selectedOrgUnit.length > 0) {
        let OrgID = this.selectedOrgUnit;
        OrgID = OrgID.split(',');
        for (var i = 0; i < OrgID.length; i++) {
          this.oIds.push(OrgID[i]);
        }
      }
    }

    if (this.selectedCostCenter != undefined) {
      if (this.selectedCostCenter.length > 0) {
        let CostID = this.selectedCostCenter;
        CostID = CostID.split(',');
        for (var i = 0; i < CostID.length; i++) {
          this.cIds.push(CostID[i]);
        }
      }
    }
  }
  setFormData(data) {
    this.searchParameterAdd();
    this.loadReportsData.PageNumber = (data.first + data.rows) / data.rows;
    this.loadReportsData.EmployeeIDs = this.eIds;
    this.loadReportsData.organisationIDs = this.oIds;
    this.loadReportsData.CostCenterIDs = this.cIds;
    this.loadReportsData.No_Of_Records = data.rows;
    this.loadReportsData.Keyword = this.searchKeyword;
    return this.loadReportsData;
  }

  exportExcel() {
    this.searchParameterAdd();
    var objectAPI = { EmployeeIDs: this.eIds, organisationIDs: this.oIds, CostCenterIDs: this.cIds };
    this.spinner.show();
    this._appServ.getReportExcel(objectAPI).subscribe((result) => {
      this.spinner.hide();
      this.excelData = [];
      this.orgReportsData = JSON.parse(JSON.stringify(result));
      this.orgReportsData.forEach(data => {
        let subObj = {
          type: data.GourpName,
          categoryCount: 0
        }
        if (this.subData.length > 0) {
          if (this.subData.filter(s => s.type === data.GourpName).length == 0) {
            this.subData.push(subObj);
          }
        } else {
          this.subData.push(subObj);
        }
        if (this.categoriesData.indexOf(data.CategoryName) === -1) {
          this.categoriesData.push(data.CategoryName);
        }
        if (this.currencyData.indexOf(data.CurrencyName) === -1) {
          this.currencyData.push(data.CurrencyName);
        }
      });
      let tempRepObj = [];
      this.orgReportsData.forEach(data => {
        let rObj = {
          Organization: data.OrganizationName,
          CostCenter: data.CostCenterName,
          Name: data.FirstName + ' ' + data.LastName,
          employeeId: data.EmployeeId,
          PersonalNumber: data.PersonalNumber
        }
        if (tempRepObj.length > 0) {
          let dupCount = tempRepObj.filter(t => t.employeeId === data.EmployeeId);
          if (dupCount.length === 0) {
            tempRepObj.push(rObj);
            this.excelData.push(rObj);
          }
        } else {
          tempRepObj.push(rObj);
          this.excelData.push(rObj);
        }
      });

      tempRepObj.forEach((repObj) => {
        repObj.subData = [];
        let filtObj = this.orgReportsData.filter(org => org.EmployeeId == repObj.employeeId);
        for (let i = 0; i < this.subData.length; i++) {
          let categories = [];
          let type = this.subData[i].type;
          let foundSubData = filtObj.filter(f => f.GourpName == this.subData[i].type);
          foundSubData.forEach((sub, index) => {
            let catObj = {
              catName: sub.CategoryName,
              catValue: sub.CostLimitDESC
            };
            categories.push(catObj);
            this.excelData.forEach(d => {
              if (d.employeeId == repObj.employeeId) {
                let sign = sub.CategoryName;
                d[`${type} - ${sign}`] = sub.CostLimitDESC;
              }
            })
            this.subData.forEach(s => {
              if (s.type == type) {
                s.categoryCount = index + 1;
              }
            })
          });
          let subDataObj = {};
          if (categories.length > 0) {
            subDataObj = {
              type: type,
              categories: categories
            }
          } else {

          }
          repObj.subData.push(subDataObj);
        }
      });

      this.excelData.forEach(e => {
        delete e.employeeId;
        delete e.subData;
      })

      const worksheet = XLSX.utils.json_to_sheet(this.excelData);
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "ReportsTables");

      if (this.reportsData.length > 0)
        this.isReport = false;
      else
        this.isReport = true;
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    let EXCEL_EXTENSION = '.xlsx';
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
  }

}
