import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SharedService } from '../shared/shared.service';
import { AppServicesService } from '../services/app-services.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class UserManagementComponent implements OnInit {

  solutionSpaces = [];
  roles: any;
  basfId = "";
  firstName = "";
  lastName = "";
  emailId = "";
  selectedRole: any;
  //selectedSolutionSpaces = [];
  orgUsersData: any;
  usersData: any;
  userCols: any;
  apiResp: any;
  editing: boolean = false;
  id = null;
  userName: any;
  constructor(private _commonService: SharedService, private _appServ: AppServicesService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit() {
    this.userName = sessionStorage.getItem('ACSuserName');
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null) {
      this.router.navigate(['/login']);
    }
    this.BindRoles();

    this.userCols = [
      { field: "BasfId", header: "Login ID", width: "100px" },
      { field: "FirstName", header: "First Name", width: "150px" },
      { field: "LastName", header: "Last Name", width: "150px" },
      { field: "Email", header: "Email", width: "220px" },
      { field: "RoleName", header: "Role", width: "80px" }
    ]
  }

  BindRoles() {
    this.spinner.show();
    this._appServ.getRoles().subscribe((result) => {
      // this.spinner.hide();
      this.GetUsers();
      this.roles = result;
    },
      (error) => {
        this.GetUsers();
        // this.spinner.hide();
      });
  }
  GetUsers() {
    this.spinner.show();
    this._appServ.getAppUsers().subscribe((result) => {
      this.spinner.hide();
      this.usersData = result;
    },
      (error) => {
        if (error.status == 401){
          this._appServ.getSAMLServiceAuthorize();
          //this._commonService.ToastMsg("Error", "Authorization failed", sessionStorage.getItem("UnAuthorizeMsg"));
        }          
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  transform(value: any): any {
    if (value === undefined)
      return undefined;
    return value.replace(/\s/g, "");
  }
  ToastrWarning(Title, Message) {
    this._commonService.ToastMsg("Warning", Title, Message);
  }
  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }
  saveUser() {
    if (this.transform(this.basfId) == '' || this.transform(this.basfId) == undefined) {
      document.getElementById("basfId").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.transform(this.firstName) == '' || this.transform(this.firstName) == undefined) {
      document.getElementById("firstName").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.transform(this.lastName) == '' || this.transform(this.lastName) == undefined) {
      document.getElementById("lastName").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    if (this.transform(this.emailId) == '' || this.transform(this.emailId) == undefined) {
      document.getElementById("emailId").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    else {
      if (!this.validateEmail(this.emailId)) {
        document.getElementById("emailId").focus();
        this.ToastrWarning("Email InValid!", "Enter valid Email");
        return false;
      }
    }
    if (this.selectedRole == '' || this.selectedRole == undefined) {
      document.getElementById("role").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return false;
    }
    this.spinner.show();
    let userInfo = {
      id: this.id,
      BasfId: this.basfId,
      firstName: this.firstName,
      lastName: this.lastName,
      EmailId: this.emailId,
      Role: this.selectedRole,
      ActionID: "1",
      CreatedBy: ''
    }
    this._appServ.saveUsers(userInfo).subscribe((result) => {
      this.spinner.hide();
      this.GetUsers();
      this._commonService.ToastMsg("Success", "Success!", result);
    },
      (error) => {
        this._commonService.ToastMsg("Error", "Error!", error);
        this.spinner.hide();
      });

    this.GetUsers();
    this.onCancel();
  }

  onRowEditInit(rowData) {
    this.editing = true;
    this.basfId = rowData.BasfId;
    this.id = rowData.UserId;
    this.firstName = rowData.FirstName;
    this.lastName = rowData.LastName;
    this.emailId = rowData.Email;
    this.selectedRole = rowData.RoleId;
  }

  onCancel() {
    this.editing = false;
    this.basfId = "";
    this.id = "";
    this.firstName = "";
    this.lastName = "";
    this.emailId = "";
    this.selectedRole = "";
  }
  generateReport() {
    this.spinner.show();
    let sheetCols = this.userCols;
    this._commonService.exportAsExcelFile(this.usersData, "Users-Report", "Users", sheetCols);
    this._commonService.ToastMsg("Success", "Success", "Downloaded Successfully");
    this.spinner.hide();
  }
  onDeleteUser(rowData) {
    let userInfo = {
      id: rowData.UserId,
      BasfId: '',
      firstName: '',
      lastName: '',
      EmailId: '',
      Role: '',
      ActionID: "2",
      CreatedBy: ''
    }
    this.spinner.show();
    this._appServ.saveUsers(userInfo).subscribe((result) => {
      this.spinner.hide();
      this.GetUsers();
      this._commonService.ToastMsg("Success", "Success!", result);
    },
      (error) => {
        this._commonService.ToastMsg("Error", "Error!", error);
        this.spinner.hide();
      });
  }
}
