import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServicesService } from '../services/app-services.service';
import { SharedService } from '../shared/shared.service';
import { ConfirmationService } from 'primeng/api';
import { debugOutputAstAsTypeScript } from '@angular/compiler';

@Component({
  selector: 'app-ipc-modfication-request',
  templateUrl: './ipc-modfication-request.component.html',
  styleUrls: ['./ipc-modfication-request.component.css'],
  providers: [
    ConfirmationService,
  ]
})
export class IpcModficationRequestComponent implements OnInit {

  displayDialog: boolean = false;
  dialogHeader: string = 'Approval Confirmation';
  isCategoryDisable: boolean = false;
  isconfirmDialogVisible: boolean = false;
  isipcconfirmDialogVisible : boolean = false;
  userName: any;
  Categories: any;
  NewCategory = {
    Id: '',
    ActionID: '0',
    CategoryId: '',
    Limit: '',
    CreatedBy: ''
  };

  NewIpcCategory = {
    EmployeeId: '',
    CategoryId: '',
    RequestedIPCCostLimitId: '',
    RequestedCostLimit: '',
    IsApproved: '',
    ApprovedBy: '',
    CreatedBy: '',
    ApproveStatus:'',
    IsChecked:false,
  };
  IsAllChecked: boolean = false;
  CategoryCols: any;
  CategoryApprovalCols: any;
  CategoryApprovalData: any;
  CategoryData: any;
  CategoryIpcApprovalData: any;
  editing: boolean = false;
  loggeduserObj: any;
  rowtabledata: any;
  ipcselectallmessage:any;
  constructor(private confirmation: ConfirmationService, private _commonService: SharedService, private spinner: NgxSpinnerService, private router: Router, private _appServ: AppServicesService) { }

  ngOnInit() {
    this.userName = sessionStorage.getItem('ACSuserName');
    this.loggeduserObj = JSON.parse(sessionStorage.getItem("loggeduserObj"));
    let UserRole = "";
    if (this.loggeduserObj != null && this.loggeduserObj != undefined && this.loggeduserObj != "undefined") {
      UserRole = this.loggeduserObj.ParamRoleID;
    }
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null || UserRole != "1") {
      this.router.navigate(['/login']);
    }
    
    
    this.CategoryApprovalCols = [
     
      { field: "SNO", header: "S.No", width: "50px" },
      { field: "Name", header: "Name", width: "100px" },
      { field: "CategoryName", header: "Category Name", width: "100px" },
      { field: "CostLimitValue", header: "Limit", width: "100px" },
      { field: "OldLimit", header: "Limit Desc", width: "100px" },
      { field: "Requestor", header: "Requested By", width: "100px" }
    ];

    this.GetLimitSelf();
  }
  
  
  GetLimitSelf() {
    
    this.spinner.show();
      var objCategory = { CategoryId: '5' }
    this._appServ.getIpccostLimitChangeRequests(objCategory).subscribe((result) => {
      
      this.spinner.hide();
      this.CategoryIpcApprovalData = result;

      for (var i = 0; i < this.CategoryIpcApprovalData.length; i++) {
        this.CategoryIpcApprovalData[i].IsChecked = false;
      }

    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  ToastrWarning(Title, Message) {
    this._commonService.ToastMsg("Warning", Title, Message);
  }


  // onCancel() {
  //   this.NewCategory = {
  //     Id: '',
  //     ActionID: '0',
  //     CategoryId: '',
  //     Limit: '',
  //     CreatedBy: ''
  //   };
  //   this.editing = false;
  //   this.isCategoryDisable = false;
  // }
  // onRowEditInit(rowData) {
  //   this.editing = true;
  //   this.NewCategory.Id = rowData.Id;
  //   this.NewCategory.ActionID = '1';
  //   this.NewCategory.Limit = rowData.CostLimitValue;
  //   this.NewCategory.CategoryId = rowData.CategoryID;
  //   this.isCategoryDisable = true;
  // }
  // onCancelEdit() {
  //   this.editing = false;
  //   this.NewCategory.Id = "";
  //   this.NewCategory.ActionID = '0';
  //   this.NewCategory.CreatedBy = this.loggeduserObj.ParamName;
  //   this.NewCategory.Limit = "";
  //   this.isCategoryDisable = false;
  // }
  // onDeleteUser(rowData) {
  //   this.spinner.show();
  //   this.NewCategory.Id = rowData.Id;
  //   this.NewCategory.ActionID = '2';
  //   this.NewCategory.CreatedBy = this.loggeduserObj.ParamName;
  //   this.NewCategory.CategoryId = "";
  //   this.NewCategory.Limit = "";
  //   this._appServ.SaveCategory(this.NewCategory).subscribe((result) => {
  //     if (result == "Saved" || result == "Updated" || result == "Deleted") {
  //       var SuccessMsg = "Limit " + result + " Successfully";
  //       this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
  //       this.onCancel();
  //       this.GetLimitSelf();
  //     }
  //     else {
  //       this.ToastrWarning("Warning!", result);
  //       this.onCancel();
  //     }
  //     this.spinner.hide();
  //   },
  //     (error) => {
  //       if (error.status == 401) {
  //         this._appServ.getSAMLServiceAuthorize();
  //       }
  //       else
  //         this._commonService.ToastMsg("Error", "Error!", error.message);
  //       this.spinner.hide();
  //     });
  // }
  // onConfirmApproval(rowData) {
  //   var objData = {
  //     Id: rowData.CostLimitId,
  //     CategoryId: rowData.CategoryId,
  //     EmployeeCostLimitExceptionId: rowData.EmployeeCostLimitExceptionId,
  //     ApproveStatus: rowData.ApprovalStatus,
  //     CreatedBy: this.loggeduserObj.ParamName
  //   };
  //   this.spinner.show();
  //   this._appServ.SaveApprovalCategoryException(objData).subscribe((result) => {
  //     this._commonService.ToastMsg("Success", "Success!", result);
  //    // this.LoadApprovalCategory(rowData.CategoryId, rowData.CostLimitId);
  //     this.spinner.hide();
  //   },
  //     (error) => {
  //       if (error.status == 401) {
  //         this._appServ.getSAMLServiceAuthorize();
  //       }
  //       else
  //         this._commonService.ToastMsg("Error", "Error!", error.message);
  //       this.spinner.hide();
  //     });
  // }
  // onSuccessApproval(rowData) {
  //   this.dialogHeader = "Approval Confirmation";
  //   var confirmMessage = `Are you sure to Approve '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
  //   rowData.ApprovalStatus = "A";
  //   console.log('row App ', rowData);
  //   this.confirmation.confirm({
  //     key: 'confirm-approval',
  //     message: confirmMessage,
  //     accept: () => { this.onConfirmApproval(rowData); },
  //   });
  // }
  // onSuccessRejection(rowData) {
  //   this.dialogHeader = "Rejection Confirmation";
  //   var confirmMessage = `Are you sure to Reject '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
  //   rowData.ApprovalStatus = "R";
  //   console.log('row Rej ', rowData);
  //   this.confirmation.confirm({
  //     key: 'confirm-approval',
  //     message: confirmMessage,
  //     accept: () => { this.onConfirmApproval(rowData); },
  //   });
  // }

  

  // saveCategory() {
  //   if (this.NewCategory.CategoryId == "") {
  //     document.getElementById("selCategory").focus();
  //     this.ToastrWarning("Field Required!", "Fields are Mandatory");
  //     return;
  //   }
  //   if (this.NewCategory.Limit == "") {
  //     document.getElementById("txtLimit").focus();
  //     this.ToastrWarning("Field Required!", "Fields are Mandatory");
  //     return;
  //   }
  //   this.spinner.show();
  //   if(this.NewCategory.CategoryId !='5'){
  //   this.NewCategory.CreatedBy = this.loggeduserObj.ParamName;
  //   this._appServ.SaveCategory(this.NewCategory).subscribe((result) => {
      
  //     if (result == "Saved" || result == "Updated" || result == "Deleted") {
  //       var SuccessMsg = "Limit " + result + " Successfully";
  //       this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
  //       this.GetLimitSelf();
  //       this.onCancelEdit();
  //     }
  //     else {
  //       this.ToastrWarning("Warning!", result);
  //     }
  //     this.spinner.hide();
  //   },
  //     (error) => {
  //       if (error.status == 401) {
  //         this._appServ.getSAMLServiceAuthorize();
  //       }
  //       else
  //         this._commonService.ToastMsg("Error", "Error!", error.message);
  //       this.spinner.hide();
  //     });
  //   }
  //   else{

  //     this.NewIpcCategory.CategoryId = this.NewCategory.CategoryId;
  //     this.NewIpcCategory.EmployeeId = this.loggeduserObj.ParamEmployeeId;
  //     this.NewIpcCategory.CreatedBy = this.loggeduserObj.ParamEmployeeId;
  //     this.NewIpcCategory.RequestedCostLimit = this.NewCategory.Limit;
  //     this._appServ.SaveIPCCostLimitChangeRequests(this.NewIpcCategory).subscribe((result) => {
        
  //       if (result == "Saved" || result == "Updated" || result == "Deleted") {
  //         var SuccessMsg = "Limit " + result + " Successfully";
  //         this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
  //         this.GetLimitSelf();
  //         this.onCancelEdit();
  //       }
  //       else {
  //         this.ToastrWarning("Warning!", result);
  //       }
  //         this.spinner.hide();
  //     },
  //       (error) => {
  //         if (error.status == 401) {
  //           this._appServ.getSAMLServiceAuthorize();
  //         }
  //         else
  //           this._commonService.ToastMsg("Error", "Error!", error.message);
  //         this.spinner.hide();
  //       });
  //   }
  // }

  
  // onSuccessIpcRejection(rowData) {
  //   this.dialogHeader = "Rejection Confirmation";
  //   var confirmMessage = `Are you sure to Reject '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
  //   rowData.ApproveStatus = "NO";
  //   rowData.IsApproved = 0;
  //   rowData.ApprovedBy = this.loggeduserObj.ParamEmployeeId;
  //   this.isipcconfirmDialogVisible = true;
  //   console.log('row Rej ', rowData);
  //   this.confirmation.confirm({
  //     key: 'confirm-approval',
  //     message: confirmMessage,
  //     accept: () => { this.onConfirmIpcApproval(rowData); },
  //   });
  //   this.isipcconfirmDialogVisible = false;
  // }
 
  // onChecked(rowData)
  // {
  //   var IsChecked = rowData.IsChecked;
  // }


  onAllChecked(ev)
  {
    this.CategoryIpcApprovalData.forEach(x => x.IsChecked = ev.target.checked);
  }



  onConfirmIpcApproval(rowData, Requiredstatus) {
  
    this.spinner.show();
    var astatus;
    var avalue;
    if(Requiredstatus == "Approve"){
       astatus = "YES";
       avalue = 1;
    }
    else if (Requiredstatus == "Reject")
    {
          astatus = "NO";
          avalue = 0;
    }
    
    if(this.IsAllChecked)
    {
        for (var i = 0; i < rowData.length; i++) {
        
      rowData[i].ApproveStatus = astatus;
      rowData[i].IsApproved = avalue;
      rowData[i].ApprovedBy = this.loggeduserObj.ParamEmployeeId;
      
          this._appServ.UpdateIpcApprovalReject(rowData[i]).subscribe((result) => {
            
            this.ipcselectallmessage = result;
            if (this.ipcselectallmessage == "Updated") {
              if(astatus == "YES")
              {
            this._commonService.ToastMsg("Success",this.ipcselectallmessage, "Approved Successfully!");
             }
          else {
          this._commonService.ToastMsg("Warning", this.ipcselectallmessage, "Rejected Successfully!");
             }
              
            this.GetLimitSelf();
            //this.onCancelEdit();
            }

            this.spinner.hide();
        },
          (error) => {
            if (error.status == 401) {
              this._appServ.getSAMLServiceAuthorize();
            }
            else
              this._commonService.ToastMsg("Error", "Error!", error.message);
            this.spinner.hide();
          });
      }
      
    }
    else {
      for (var i = 0; i < rowData.length; i++) {
            if(rowData[i].IsChecked) {
            rowData[i].ApproveStatus = astatus;
            rowData[i].IsApproved = avalue;
            rowData[i].ApprovedBy = this.loggeduserObj.ParamEmployeeId;
            
            this._appServ.UpdateIpcApprovalReject(rowData[i]).subscribe((result) => {
            
              this.ipcselectallmessage = result;
            if (this.ipcselectallmessage == "Updated") {
              
              if(astatus == "YES")
              {
            this._commonService.ToastMsg("Success",this.ipcselectallmessage, "Approved Successfully!");
             }
          
             else {
          this._commonService.ToastMsg("Warning", this.ipcselectallmessage, "Rejected Successfully!");
             }
              
            this.GetLimitSelf();
           // this.onCancelEdit();
            }
                        this.spinner.hide();
          },
            (error) => {
              if (error.status == 401) {
                this._appServ.getSAMLServiceAuthorize();
              }
              else
                this._commonService.ToastMsg("Error", "Error!", error.message);
              this.spinner.hide();
            });
          }
            
          }
    }

    this.spinner.hide();
  }


  onSuccessIpcRejection(rowData) {
    this.dialogHeader = "Rejection Confirmation";
    var confirmMessage = 'Are you sure to Reject Selected Indirect Procurement Contract Approvals.';
    this.isipcconfirmDialogVisible = true;
    this.confirmation.confirm({
      key: 'confirm-approval',
      message: confirmMessage,
      accept: () => { this.onConfirmIpcApproval(rowData,"Reject"); },
    });
    this.isipcconfirmDialogVisible = false;
  }

  onSuccessIpcApproval(rowData) {
    this.dialogHeader = "Approval Confirmation";
    var confirmMessage = 'Are you sure to Approve Selected Indirect Procurement Contract Approvals.';
    this.isipcconfirmDialogVisible = true;
    this.confirmation.confirm({
      key: 'confirm-approval',
      message: confirmMessage,
      accept: () => { this.onConfirmIpcApproval(rowData, "Approve"); },
    });
    this.isipcconfirmDialogVisible = false;
  }


  // onSuccessIpcApproval(rowData) {
    
  //   this.dialogHeader = "Approval Confirmation";
  //   var confirmMessage = `Are you sure to Approve '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
  //   rowData.ApproveStatus = "YES";
  //   rowData.IsApproved = 1;
  //   rowData.ApprovedBy = this.loggeduserObj.ParamEmployeeId;
    
  //   this.isipcconfirmDialogVisible = true;
  //   this.confirmation.confirm({
  //     key: 'confirm-approval',
  //     message: confirmMessage,
  //     accept: () => { this.onConfirmIpcApproval(rowData); },
  //   });
  //   this.isipcconfirmDialogVisible = false;
  // }

    }
