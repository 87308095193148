import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServicesService } from '../services/app-services.service';
import { SharedService } from '../shared/shared.service';
import { ConfirmationService } from 'primeng/api';
import { debugOutputAstAsTypeScript } from '@angular/compiler';

@Component({
  selector: 'app-category-management',
  templateUrl: './category-management.component.html',
  styleUrls: ['./category-management.component.css'],
  providers: [
    ConfirmationService,
  ]
})
export class CategoryManagementComponent implements OnInit {
  displayDialog: boolean = false;
  dialogHeader: string = 'Approval Confirmation';
  isCategoryDisable: boolean = false;
  isconfirmDialogVisible: boolean = false;
  isipcconfirmDialogVisible : boolean = false;
  userName: any;
  Categories: any;
  NewCategory = {
    Id: '',
    ActionID: '0',
    CategoryId: '',
    Limit: '',
    CreatedBy: ''
  };

  NewIpcCategory = {
    IPCCostLimitId: '',
    CategoryId: '',
    CostLimitDesc: '',
    CostLimitValue: '',
    ARole: '',
    CreatedBy: '',
    ModifiedBy:'',
    IsActive: ''
  };

  CategoryCols: any;
  CategoryApprovalCols: any;
  CategoryApprovalData: any;
  CategoryData: any;
  CategoryIpcApprovalData: any;
  editing: boolean = false;
  loggeduserObj: any;
  rowtabledata: any;
  constructor(private confirmation: ConfirmationService, private _commonService: SharedService, private spinner: NgxSpinnerService, private router: Router, private _appServ: AppServicesService) { }

  ngOnInit() {
    
    
    this.userName = sessionStorage.getItem('ACSuserName');
    this.loggeduserObj = JSON.parse(sessionStorage.getItem("loggeduserObj"));
    let UserRole = "";
    if (this.loggeduserObj != null && this.loggeduserObj != undefined && this.loggeduserObj != "undefined") {
      UserRole = this.loggeduserObj.ParamRoleID;
    }
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null || UserRole != "1") {
      this.router.navigate(['/login']);
    }
    this.loadCategories();
    this.CategoryCols = [
      { field: "SNO", header: "S.No", width: "50px" },
      { field: "CategoryName", header: "Category Name", width: "200px" },
      { field: "CostLimitValue", header: "Limit", width: "150px" },
      { field: "Name", header: "Created By", width: "200px" }
    ];
    this.CategoryApprovalCols = [
     
      { field: "SNO", header: "S.No", width: "50px" },
      { field: "Name", header: "Name", width: "100px" },
      { field: "CategoryName", header: "Category Name", width: "100px" },
      { field: "CostLimitValue", header: "Limit", width: "100px" },
      { field: "OldLimit", header: "Old Limit", width: "100px" },
      { field: "Requestor", header: "Requested By", width: "100px" }
      
    ];
  }
  LoadApprovalCategory(CategoryID, CostLimitId) {
    var objData = { Id: CostLimitId, CategoryId: CategoryID }
    this.spinner.show();
    this._appServ.getCategoryExceptionApproval(objData).subscribe((result) => {
      this.spinner.hide();
      this.CategoryApprovalData = result;
      this.displayDialog = true;
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  showEmployeeDialog(rowData) {
    this.LoadApprovalCategory(rowData.CategoryID, rowData.CostLimitId);
  }
  loadCategories() {
    this.spinner.show();
    this._appServ.getCategories().subscribe((result) => {
      this.spinner.hide();
      this.Categories = result;
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  GetLimitSelf() {
    if (this.NewCategory.CategoryId == "") {
      this.CategoryData = [];
      this.onCancel();
      return;
    }
    this.spinner.show();

   if (this.NewCategory.CategoryId !='5'){
    var objCategory = { CategoryId: this.NewCategory.CategoryId }
    this._appServ.getCategoryException(objCategory).subscribe((result) => {
      this.spinner.hide();
      this.CategoryData = result;
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
    }
    else {
   
    this._appServ.getipcccostlimitcategory().subscribe((result) => {
      this.spinner.hide();
      this.CategoryIpcApprovalData = result;
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });

    }
  }
  ToastrWarning(Title, Message) {
    this._commonService.ToastMsg("Warning", Title, Message);
  }
  onCancel() {
    this.NewCategory = {
      Id: '',
      ActionID: '0',
      CategoryId: '',
      Limit: '',
      CreatedBy: ''
    };
    this.editing = false;
    this.isCategoryDisable = false;
  }
  onRowEditInit(rowData) {
    
    this.editing = true;
    this.NewCategory.Id = rowData.Id;
    this.NewCategory.ActionID = '1';
    this.NewCategory.Limit = rowData.CostLimitValue;
    this.NewCategory.CategoryId = rowData.CategoryID;
    this.isCategoryDisable = true;
  }
  onCancelEdit() {
    
    this.editing = false;
    this.NewCategory.Id = "";
    this.NewCategory.ActionID = '0';
    this.NewCategory.CreatedBy = this.loggeduserObj.ParamName;
    this.NewCategory.Limit = "";
    this.isCategoryDisable = false;
  }
  onDeleteUser(rowData) {
    
    this.spinner.show();
    this.NewCategory.Id = rowData.Id;
    this.NewCategory.ActionID = '2';
    this.NewCategory.CreatedBy = this.loggeduserObj.ParamName;
    this.NewCategory.CategoryId = "";
    this.NewCategory.Limit = "";
    this._appServ.SaveCategory(this.NewCategory).subscribe((result) => {
      if (result == "Saved" || result == "Updated" || result == "Deleted") {
        var SuccessMsg = "Limit " + result + " Successfully";
        this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
        this.onCancel();
        this.GetLimitSelf();
      }
      else {
        this.ToastrWarning("Warning!", result);
        this.onCancel();
      }
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  onRowIPCEditInit(rowData) {
    
    this.editing = true;
    this.NewIpcCategory.IPCCostLimitId = rowData.id;
    this.NewCategory.Limit = rowData.CostLimitValue;
    this.NewIpcCategory.CategoryId = rowData.CategoryID;
    this.isCategoryDisable = true;
  }

  onRowIPCDeleteUser(rowData) {
    
    this.spinner.show();
    this.NewIpcCategory.IPCCostLimitId = rowData.id;
    this.NewIpcCategory.ModifiedBy = this.loggeduserObj.ParamName;
    this.NewIpcCategory.IsActive = "0";
    this.NewIpcCategory.CostLimitValue = rowData.CostLimitValue;
    this._appServ.UpdateIPCCostLimit(this.NewIpcCategory).subscribe((result) => {
      
      if (result == "Saved" || result == "Updated" || result == "Deleted") {
        var SuccessMsg = "Limit " + result + " Successfully";
        this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
        this.onCancel();
        this.GetLimitSelf();
      }
      else {
        this.ToastrWarning("Warning!", result);
        this.onCancel();
      }
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }



  onConfirmApproval(rowData) {
    var objData = {
      Id: rowData.CostLimitId,
      CategoryId: rowData.CategoryId,
      EmployeeCostLimitExceptionId: rowData.EmployeeCostLimitExceptionId,
      ApproveStatus: rowData.ApprovalStatus,
      CreatedBy: this.loggeduserObj.ParamName
    };
    this.spinner.show();
    this._appServ.SaveApprovalCategoryException(objData).subscribe((result) => {
      this._commonService.ToastMsg("Success", "Success!", result);
      this.LoadApprovalCategory(rowData.CategoryId, rowData.CostLimitId);
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }
  onSuccessApproval(rowData) {
    this.dialogHeader = "Approval Confirmation";
    var confirmMessage = `Are you sure to Approve '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
    rowData.ApprovalStatus = "A";
    console.log('row App ', rowData);
    this.confirmation.confirm({
      key: 'confirm-approval',
      message: confirmMessage,
      accept: () => { this.onConfirmApproval(rowData); },
    });
  }
  onSuccessRejection(rowData) {
    this.dialogHeader = "Rejection Confirmation";
    var confirmMessage = `Are you sure to Reject '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
    rowData.ApprovalStatus = "R";
    console.log('row Rej ', rowData);
    this.confirmation.confirm({
      key: 'confirm-approval',
      message: confirmMessage,
      accept: () => { this.onConfirmApproval(rowData); },
    });
  }

  // onChecked(rowData)
  // {
  //   var IsChecked = rowData.IsChecked;
  // }
  // onAllChecked(rowData)
  // {
  //   var IsAllChecked = rowData.IsAllChecked;
  // }

  saveCategory() {
    
    if (this.NewCategory.CategoryId == "") {
      document.getElementById("selCategory").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return;
    }
    if (this.NewCategory.Limit == "") {
      document.getElementById("txtLimit").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return;
    }
    this.spinner.show();
    if(this.NewCategory.CategoryId !='5'){
    this.NewCategory.CreatedBy = this.loggeduserObj.ParamName;
    this._appServ.SaveCategory(this.NewCategory).subscribe((result) => {
      
      if (result == "Saved" || result == "Updated" || result == "Deleted") {
        var SuccessMsg = "Limit " + result + " Successfully";
        this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
        this.GetLimitSelf();
        this.onCancelEdit();
      }
      else {
        this.ToastrWarning("Warning!", result);
      }
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
    }
    else{

      //this.NewIpcCategory.id = 
      this.NewIpcCategory.ModifiedBy = this.loggeduserObj.ParamName;
      this.NewIpcCategory.CostLimitValue = this.NewCategory.Limit;
      this.NewIpcCategory.IsActive = "1";
      this._appServ.UpdateIPCCostLimit(this.NewIpcCategory).subscribe((result) => {
        
        if (result == "Saved" || result == "Updated" || result == "Deleted") {
          var SuccessMsg = "Limit " + result + " Successfully";
          this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
          this.GetLimitSelf();
          this.onCancelEdit();
        }
        else {
          this.ToastrWarning("Warning!", result);
        }
          this.spinner.hide();
      },
        (error) => {
          if (error.status == 401) {
            this._appServ.getSAMLServiceAuthorize();
          }
          else
            this._commonService.ToastMsg("Error", "Error!", error.message);
          this.spinner.hide();
        });
    }
  }

  // onSuccessIpcApproval(rowData) {
  //   // = this.CategoryIpcApprovalData;
  //   this.dialogHeader = "Approval Confirmation";
  //   var confirmMessage = `Are you sure to Approve '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
  //   rowData.ApproveStatus = "YES";
  //   rowData.IsApproved = 1;
  //   rowData.ApprovedBy = this.loggeduserObj.ParamEmployeeId;
  //   console.log('row App ', rowData);
  //   this.isipcconfirmDialogVisible = true;
  //   this.confirmation.confirm({
  //     key: 'confirm-approval',
  //     message: confirmMessage,
  //     accept: () => { this.onConfirmIpcApproval(rowData); },
  //   });
  //   this.isipcconfirmDialogVisible = false;
  // }
  // onSuccessIpcRejection(rowData) {
  //   this.dialogHeader = "Rejection Confirmation";
  //   var confirmMessage = `Are you sure to Reject '${rowData.Name}' for the Limit ${rowData.CostLimitValue}?`
  //   rowData.ApproveStatus = "NO";
  //   rowData.IsApproved = 0;
  //   rowData.ApprovedBy = this.loggeduserObj.ParamEmployeeId;
  //   this.isipcconfirmDialogVisible = true;
  //   console.log('row Rej ', rowData);
  //   this.confirmation.confirm({
  //     key: 'confirm-approval',
  //     message: confirmMessage,
  //     accept: () => { this.onConfirmIpcApproval(rowData); },
  //   });
  //   this.isipcconfirmDialogVisible = false;
  // }
 
  // onConfirmIpcApproval(rowData) {
  //   // var objData = {
  //   //   Id: rowData.id,
  //   //   CategoryId: rowData.CategoryId,
  //   //   ApproveStatus: rowData.ApprovalStatus,
  //   //   CreatedBy: this.loggeduserObj.ParamEmployeeId
  //   // };
  //   this.spinner.show();
  //   this._appServ.UpdateIpcApprovalReject(rowData).subscribe((result) => {
        
      
  //     if (result == "Updated") {
         
  //       if(rowData.ApproveStatus == "YES")
  //          {
  //           this._commonService.ToastMsg("Success",result, "Approved Successfully!");
  //         }
  //         else{
  //         this._commonService.ToastMsg("Warning", result, "Rejected Successfully!");
  //       }
  //     this.GetLimitSelf();
  //     this.onCancelEdit();
  //     }
      
  //     this.spinner.hide();
  //   },
  //     (error) => {
  //       if (error.status == 401) {
  //         this._appServ.getSAMLServiceAuthorize();
  //       }
  //       else
  //         this._commonService.ToastMsg("Error", "Error!", error.message);
  //       this.spinner.hide();
  //     });
  // }


  // IpcRejection(rowData) {
    
  //   for (var i = 0; i < rowData.length; i++) {
  //     if(rowData[i].IsChecked)
  //     this.rowtabledata = rowData[i];
  //     //this.rowtabledata.push(rowData[i]);
  //   }
  //   this.onSuccessIpcRejection(this.rowtabledata);
  // }

  // IpcApproval(rowData) {
    
  //   for (var i = 0; i < rowData.length; i++) {
  //     if(rowData[i].IsChecked) 
  //     this.rowtabledata = rowData[i];
  //    // this.rowtabledata.push(rowData[i]);
  //   }
  //   this.onSuccessIpcApproval(this.rowtabledata);
  // }

}
