import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute, Router } from '@angular/router';
import { AppServicesService } from '../services/app-services.service';
import { SharedService } from '../shared/shared.service';
import { ConfirmationService } from 'primeng/api';

@Component({
  selector: 'app-company-management',
  templateUrl: './company-management.component.html',
  styleUrls: ['./company-management.component.css'],
  providers: [
    ConfirmationService,
  ]
})
export class CompanyManagementComponent implements OnInit {

  isCompanyDisable: boolean = false;
  userName: any;
  Companies: any;
  NewCompany = {
    Id: '',
    ActionID: '0',
    CompanyId: '',
    CompanyCode: '',
    CompanyName: '',
    CreatedBy: ''
  };

  CompanyCols: any;
  CompanyData: any;
  editing: boolean = false;
  loggeduserObj: any;
  rowtabledata: any;

  constructor(private confirmation: ConfirmationService, private _commonService: SharedService, private spinner: NgxSpinnerService, private router: Router, private _appServ: AppServicesService) { }

  ngOnInit() {
    this.userName = sessionStorage.getItem('ACSuserName');
    this.loggeduserObj = JSON.parse(sessionStorage.getItem("loggeduserObj"));
    let UserRole = "";
    if (this.loggeduserObj != null && this.loggeduserObj != undefined && this.loggeduserObj != "undefined") {
      UserRole = this.loggeduserObj.ParamRoleID;
    }
    if (this.userName == "undefined" || this.userName == undefined || this.userName == null || UserRole != "1") {
      this.router.navigate(['/login']);
    }
    this.loadCompanies();
    this.CompanyCols = [
      { field: "CompanyId", header: "S.No", width: "50px" },
      { field: "Company_Code", header: "Company Code", width: "100px" },
      { field: "Company_Name", header: "Company Name", width: "250px" },
    ];
  }

  loadCompanies() {
    this.spinner.show();
    this._appServ.getCompanies().subscribe((result) => {
      this.spinner.hide();
      this.Companies = result;
      this.CompanyData = result;
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  
  ToastrWarning(Title, Message) {
    this._commonService.ToastMsg("Warning", Title, Message);
  }
  onCancel() {
    this.NewCompany= {
      Id: '',
      ActionID: '0',
      CompanyId: '',
      CompanyCode: '',
      CompanyName: '',
      CreatedBy: ''
    };
    this.editing = false;
    this.isCompanyDisable = false;
  }
  onRowEditInit(rowData) {
    
    this.editing = true;
    this.NewCompany.Id = rowData.CompanyId;
    this.NewCompany.ActionID = '1';
    this.NewCompany.CompanyCode = rowData.Company_Code;
    this.NewCompany.CompanyName = rowData.Company_Name;
    this.NewCompany.CompanyId = rowData.CompanyId;
    this.isCompanyDisable = true;
  }
  onCancelEdit() {
    
    this.editing = false;
    this.NewCompany.Id = "";
    this.NewCompany.ActionID = '0';
    this.NewCompany.CreatedBy = this.loggeduserObj.ParamName;
    this.NewCompany.CompanyCode = '';
    this.NewCompany.CompanyName = '';
    this.isCompanyDisable = false;
  }
  onDeleteUser(rowData) {
    
    this.spinner.show();
    this.NewCompany.Id = rowData.CompanyId;
    this.NewCompany.ActionID = '2';
    this.NewCompany.CreatedBy = this.loggeduserObj.ParamName;
    this.NewCompany.CompanyId = rowData.CompanyId;
    this.NewCompany.CompanyCode = "";
    this.NewCompany.CompanyName = "";
    this._appServ.SaveCompany(this.NewCompany).subscribe((result) => {
      if (result == "Saved" || result == "Updated" || result == "Deleted") {
        var SuccessMsg = "Company " + result + " Successfully";
        this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
        this.onCancel();
        this.loadCompanies();
      }
      else {
        this.ToastrWarning("Warning!", result);
        this.onCancel();
      }
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
  }

  saveCompany() {
    
    if (this.NewCompany.CompanyCode == "") {
      document.getElementById("txtCompanyCode").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return;
    }
    if (this.NewCompany.CompanyName == "") {
      document.getElementById("txtCompanyName").focus();
      this.ToastrWarning("Field Required!", "Fields are Mandatory");
      return;
    }
    this.spinner.show();
    this.NewCompany.CreatedBy = this.loggeduserObj.ParamName;
    this._appServ.SaveCompany(this.NewCompany).subscribe((result) => {
      
      if (result == "Saved" || result == "Updated" || result == "Deleted") {
        var SuccessMsg = "Company " + result + " Successfully";
        this._commonService.ToastMsg("Success", "Success!", SuccessMsg);
        this.loadCompanies();
        this.onCancelEdit();
      }
      else {
        this.ToastrWarning("Warning!", result);
      }
      this.spinner.hide();
    },
      (error) => {
        if (error.status == 401) {
          this._appServ.getSAMLServiceAuthorize();
        }
        else
          this._commonService.ToastMsg("Error", "Error!", error.message);
        this.spinner.hide();
      });
    }
}
