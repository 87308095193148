var base_url = '';
if (window.location.hostname != "localhost") {
  base_url = `https://` + window.location.hostname + `/serviceAPI/api`;
}
else {  
  base_url = 'http://localhost:56883/api';
}

export const environment = {
  production: false,
  getSearchResults: `${base_url}/Search`,
  getRoles: `${base_url}/Role/GetRoles`,
  getAppUsers: `${base_url}/User/GetUsers`,
  ManageUsers: `${base_url}/User/ManageUser`,
  getUserInfoData: `${base_url}/SearchID/SearchEmployeeID`,
  getApprovalData: `${base_url}/Approval/ApprovalGroup`,
  getAllCategories: `${base_url}/ModifyRequest/GetCategory`,
  getLimit: `${base_url}/ModifyRequest/getself`,
  getIndividualLimit: `${base_url}/ModifyRequest/getIndividualLimit`,
  getCompanies: `${base_url}/ModifyRequest/getCompanies`,
  getIndividualCompany: `${base_url}/ModifyRequest/getIndividualCompany`,
  getGrantors: `${base_url}/RequestModification/GetGrantor`,
  getSubordinates: `${base_url}/ModifyRequest/getSubordinates`,
  getLoggedUserData: `${base_url}/Login/GetLoggedUserData`,
  getLoggedUserDataManual: `${base_url}/Login/GetLoggedUserDataManual`,
  RequestModification: `${base_url}/ModifyRequest/selfRequestMail`,
  getFilterEmployee: `${base_url}/ModifyRequest/GetFilterEmployee`,
  getManagers: `${base_url}/ModifyRequest/GetManager`,
  getApprover: `${base_url}/ModifyRequest/GetApprover`,
  Surrogate: `${base_url}/ModifyRequest/SurrogateRequestMail`,
  getReportData: `${base_url}/GetReportData`,
  getReportExcelData: `${base_url}/Report/GetReportExportData`,
  getReportExportExcelData: `${base_url}/Report/GetReportExcelExportData`,
  getReportFilterOpts: `${base_url}/Report/GetReportFilters`,  
  getSAMLServiceURL: `${base_url}/Saml/GetSamlURL`,
  getLoadingStatus:  `${base_url}/Login/GetDataLoadingStatus`,
  getCategoryException: `${base_url}/CategoryException/GetCategoryException`,
  ManageCategory: `${base_url}/CategoryException/SaveCategoryException`,
  ManageCompany: `${base_url}/CompanyManagement/ManageCompany`,
  getCategoryExceptionApproval:`${base_url}/CategoryException/GetCategoryExceptionApproval`,
  getIpccostLimitChangeRequests:`${base_url}/CategoryException/GetIPCCostLimitChangeRequests`,
  SaveApprovalCategoryException: `${base_url}/CategoryException/SaveApprovalCategoryException`,
  SaveIPCCostLimitChangeRequests: `${base_url}/ModifyRequest/SaveIPCCostLimitChangeRequests`,
  UpdateIpcApprovalReject: `${base_url}/CategoryException/UpdateIpcApprovalReject`,
  getipcccostlimitcategory:`${base_url}/CategoryException/GetIPCCostlimitCategory`,
  UpdateIPCCostLimit: `${base_url}/CategoryException/UpdateIPCCostLimit`,
  HomeEmail: `${base_url}/Home/GetEmail`,
  getIPCostLimits: `${base_url}/IPCost/getIPCOstLimts`,
  ManageIPCostLimt: `${base_url}/IPCost/ManageIPCostLimt`,
}